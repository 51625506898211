import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Menubar from "../Components/Menubar";
import "./Profilepage.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Profileedit from "../Components/Profileedit";
import Whatsapp from "../Components/Whatsapp";
import Profileproduct from "../Components/Profileproduct";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";

export default function Profilepage() {
  const baseurl = config.url
  const user = useSelector((state) => state.user)
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }

  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [], "user": [] });
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  var usrid = localStorage.getItem("loginid");
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "getuser";
    const req = { id: user[0].id };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setArray(res.data);
        setFname(res.data.user[0].firstname);
        setLname(res.data.user[0].lastname);
        setPhone(res.data.user[0].phoneno);
        setEmail(res.data.user[0].email);
        setPassword(res.data.user[0].password);
        setRepassword(res.data.user[0].password);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
}

  return (
    <>
      <div className="profilepage">
        <div className="profilepage_header">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          isNeeded={false}
          handleMenuClick={handleMenuClick}
          handleContactClick={handleContactClick}
          isPop={isPop}
          array={array}
        />
        </div>
        {/* {isPop ?
          <div className="profileeditpage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null} */}
        <Subbanner title={"My Account"} />
        <div className="profilepage_row">
        <Profileedit
          fname={fname}
          setFname={setFname}
          lname={lname}
          phone={phone}
          email={email}
          password={password}
          repassword={repassword}
          setLname={setLname}
          setPhone={setPhone}
          setEmail={setEmail}
          setPassword={setPassword}
          setRepassword={setRepassword}
        />
        </div>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
        <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
      </div>
    </>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Forgotpassword.css";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";

function Forgotpassword({ disabled, header, label1, label3, paceholder1, label2, button, handleOtpBtnClick, otp, handleOtpClick,handleResetPasswordClick }) {
    //   const dispatch = useDispatch();
    //   const user = useSelector(state => state.user);
    const baseurl = config.url;
    const navigate = useNavigate();
    //   const [array, setArray] = useState([]);

    //   const [usrpassword, setUsrPassword] = useState("");

    //   const handlePasswordclick = (e) => {
    //     e.preventDefault();
    //     setUsrPassword(e.target.value);
    //   };
    // localStorage.setItem("name",usrname);
    // localStorage.setItem("password",usrpassword);

    // const handleOtpBtnClick = (e) => {
    // e.preventDefault();
    // const url = baseurl + "login";
    // var data = { email: usrname, password: usrpassword };
    // const header = {};
    // axios
    //   .post(url, data, header)
    //   .then((res) => {
    //     if (usrname == "" && usrpassword == "") {
    //       alert("Username and password fields empty");
    //     } else if (usrname == "") {
    //       alert("Username field empty");
    //     } else if (usrpassword == "") {
    //       alert("Password field empty");
    //     } else if (res.data.length > 0) {
    //       console.log("id==>", res.data);
    //       localStorage.setItem("loginid", res.data[0].id);
    //       setArray(res.data);
    //       dispatch({ type: "setUser", payload: res.data });
    //       const shipcart = localStorage.getItem("logcart");
    //       if (shipcart) {
    //         navigate("/cartpage");
    //         localStorage.removeItem("logcart")
    //       } else {
    //         navigate("/")
    //       }
    //     } else {
    //       alert("incorrect username or password");
    //     }
    //     // }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // };
    return (
        <><div className="forgotpassword">
            <div className="forgotpassword_row1">
                <div className="otp">
                    <div className="otp_header">
                        <h2> {header}</h2>
                    </div>
                    {/* <form className="test-mailing" > */}

                        <div className="otp_header2">
                            <label> Please Enter {label1} below.</label>
                        </div>
                        <div className="otp_inner">
                            <input
                                type="text"
                                placeholder={paceholder1}
                                value={otp}
                                onChange={(e) => {
                                    handleOtpClick(e);
                                }}
                            />
                        </div>
                        {disabled ?
                            <div className="otp_inner_lab">
                                <label>{label3}</label>
                            </div> : ""}
                        <div className="otp_button">
                            <button
                                onClick={(e) => {
                                    handleOtpBtnClick(e);
                                }}
                            >
                                {button}
                            </button>
                        </div>
                    {/* </form> */}
                    <div className="otp_popup">
                        <label onClick={(e)=>handleResetPasswordClick(e)}> {label2}</label>
                    </div>
                </div>
            </div>

        </div>
        </>
    );
}
export default Forgotpassword;

import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Forgotpassword from "../Components/Forgotpassword";
import Subbanner from "../Components/Subbanner";
import Whatsapp from "../Components/Whatsapp";
import "./Ordercompletedpage.css";
import Menubar from "../Components/Menubar";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";

export default function Forgotpasswordpage() {
    const baseurl = config.url
    const navigate = useNavigate();
    const handleShopClick = () => {
        navigate("/Shoppage");
    };
    const handleCartClick = () => {
        navigate("/CartPage");
    };
    const handleLoginClick = () => {
        navigate("/Loginpage");
    };
    const handleHomeClick = () => {
        navigate("/");
    };
    const handleSignupClick = () => {
        navigate("/Loginpage");
    };
    const handleProfilePageClick = () => {
        navigate("/Profilepage");
    };
    const handleOrderListClick = () => {
        navigate("/Orderlistpage");
    };
    const handleOrderDetailPageClick = () => {
        navigate("/Orderdetailpage");
    };
    const handleWishListClick = () => {
        navigate("/wishlistpage");
    }

    const [array, setArray] = useState([]);
    const [disabled, setDisabled] = useState(false);
    // const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    const email = localStorage.getItem("emailid");

    const handleOtpBtnClick = () => {

        // const email="jinu@gmail.com"
        const url = baseurl + "validateotp";
        const req = { email: email };
        const header = {};
        axios
            .post(url, req, header)
            .then((res) => {
                console.log("res.data.otp==>", res.data)
                if (res.data[0].otp === otp) {
                    setArray(res.data.otp);
                    navigate("/resetpasswordpage");
                    setDisabled(false);
                    console.log("success")
                } else {
                    setDisabled(true);
                    console.log("failed")
                }
                // localStorage.setItem("array"+res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        // navigate("/resetpasswordpage");
    }

    const handleResetPasswordClick = (e) => {
        function betweenRandomnumber(min, max) {
            return Math.floor(
                Math.random() * (max - min + 1) + min
            )
        }
        var otp = betweenRandomnumber(100000, 999999);
        // const email1="jinu@gmail.com";
        const url = baseurl + "otpgenerate";
        var data = {
            email: email,
            otp: otp
        };
        const header = {};
        const subject = "OTP from Maharaja Gold & Diamonds";
        let message = otp;
        var body = `Your OTP is ${otp}`;
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "maharajajewellery12@gmail.com",
            Password: "942A4B188CF8D2B74B4D28E4CFE152A83A76",
            To: email,
            From: 'maharajajewellery12@gmail.com',
            Subject: subject,
            Body: body
        }).then(
            message => {
                if (message == "OK") {
                    alert("OTP send to your email " + email);
                    axios
                        .post(url, data, header)
                        .then((res) => {
                        })
                        .catch((err) => {
                            console.log(err);
                        })

                } else {
                    alert("Email address verified....")
                }


            }
        );

    }

    const [otp, setOtp] = useState("");
    const handleOtpClick = (e) => {
        e.preventDefault();
        setOtp(e.target.value);
        setDisabled(false);
    };

    const [isPop, setIsPop] = useState(false);
    const handleMenuClick = () => {
        setIsPop(current => !current);
    };
    const handleContactClick = (e) => {
        // contact1.current.focus();
        window.scrollTo(0, 10000);
    }


    return (
        <>
            <Header
                handleCartClick={handleCartClick}
                handleLoginClick={handleLoginClick}
                handleHomeClick={handleHomeClick}
                handleShopClick={handleShopClick}
                handleWishListClick={handleWishListClick}
                handleMenuClick={handleMenuClick}
                handleContactClick={handleContactClick}
                isNeeded={false}
            />
            {/* {isPop ?
          <div className="ordercompletedpage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null} */}
            <Subbanner label="Forgot Password" title={"Forgot Password"} />
            {/* <OrderCompleted handleShopClick={handleShopClick} /> */}
            <Forgotpassword
                header="OTP"
                label1="Given OTP"
                paceholder1="Enter OTP"
                label2="Resend OTP?"
                button="Submit OTP"
                handleOtpBtnClick={handleOtpBtnClick}
                otp={otp}
                handleOtpClick={handleOtpClick}
                array={array}
                disabled={disabled}
                label3={"Enter valid OTP"}
                handleResetPasswordClick={handleResetPasswordClick}
            />
            <Footer
                handleSignupClick={handleSignupClick}
                handleProfilePageClick={handleProfilePageClick}
                handleOrderListClick={handleOrderListClick}
                handleOrderDetailPageClick={handleOrderDetailPageClick}
            />
            <div className="homepage_whatsapp">
                <Whatsapp />
            </div>
        </>
    );
}

import { useEffect, useState } from "react";
import axios from "axios";
import "./FilterBarVertical.css";
import FilterBarVertical_search from "./Images/FilterBarVertical_search.png";
import LatestProducts_icons_star1 from "./Images/LatestProducts_icons_star1.png";
import LatestProducts_icons_star2 from "./Images/LatestProducts_icons_star2.png";
import config from "../config.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FilterBarVertical({
  isChecked4,
  setIsChecked4,
  isChecked3,
  setIsChecked3,
  isChecked2,
  setIsChecked2,
  setIsChecked1,
  isChecked1,
  isChecked,
  setIsChecked,
  handleCatfilterClick,
  handleSubCatfilterClick,
  handlestylefilterClick,
  handletypefilterClick,
  handlemetalfilterClick,
  handleFilterClick,
  handlePriceClick,
  styleCheckAll,
  wearTypeCheckAll,
  metalCheckAll,
  subcategoryCheckAll,
  categoryCheckAll,
  cat,
  subcat,
  metal,
  weartype,
  style,
}) {
  // const baseurl = config.url;
  // const [catArray, setCatArray] = useState([]);
  // const [subcategoryArr, setSubCategoryArr] = useState([]);

  // useEffect(() => {
  //     const url = baseurl + "fetchcategory";
  //     const req = {};
  //     const header = {};
  //     axios
  //         .post(url, req, header)
  //         .then((res) => {
  //             // if(res.data>0){
  //             setCatArray(res.data);
  //             // }
  //             console.log("category array==>", catArray);

  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  //     const url1 = baseurl + "fetchsubcategory";
  //     const req1 = {};
  //     const header1 = {};
  //     axios
  //         .post(url1, req1, header1)
  //         .then((res) => {
  //             console.log("subcart array", res.data);

  //             // if (res.data > 0) {
  //                 setSubCategoryArr(res.data);
  //             // }
  //             // setSubcat(res.data[0].subcategoryname)
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });

  // }, [])

  return (
    <>
      <div className="FilterBarVertical_outer">
        {/* ---------Product Brand--------- */}
        <div className="FilterBarVertical_ProdBrand">
          <div className="FilterBarVertical_ProdBrand_bttn">
            <button onClick={(e) => handleFilterClick()}>Apply Filter</button>
            <ToastContainer />
          </div>
          <div className="FilterBarVertical_Categories">

            <div className="FilterBarVertical_header">
              <input
                type="checkbox"
                key="1"
                onChange={(e) => categoryCheckAll(e)}
              ></input>
              <label>Categories</label>
            </div>
            <div className="FilterBarVertical_Cat_check">
              <ul>
                {cat.map((itm, indx) => {
                  return (
                    <li>
                      <input
                        type="checkbox"
                        id={itm.id}
                        //  value={isChecked}
                        checked={itm.isSelected}
                        onChange={(e) => handleCatfilterClick(e, itm, indx)}
                      ></input>
                      <label>{itm.categoryname}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="FilterBarVertical_Categories">
          <div className="FilterBarVertical_header">
            <input
              type="checkbox"
              // value={isChecked1}
              key="2"
              onChange={(e) => subcategoryCheckAll(e)}
            ></input>
            <label>Sub Categories</label>
          </div>
          <div className="FilterBarVertical_Cat_check">
            <ul>
              {subcat.map((itm, indx) => {
                return (
                  <li>
                    <input
                      type="checkbox"
                      id={itm.id}
                      // value={isChecked}
                      checked={itm.isSelected}
                      onClick={(e) => handleSubCatfilterClick(e, itm, indx)}
                    ></input>
                    <label>{itm.subcategoryname}</label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="FilterBarVertical_Categories">
          <div className="FilterBarVertical_header">
            <input
              type="checkbox"
              // value={isChecked2}
              key="3"
              onChange={(e) => metalCheckAll(e)}
            ></input>
            <label>Shop by metal</label>
          </div>
          <div className="FilterBarVertical_Cat_check">
            <ul>
              {metal.map((itm, indx) => {
                return (
                  <li>
                    <input
                      type="checkbox"
                      id={itm.id}
                      checked={itm.isSelected}
                      onClick={(e) => handlemetalfilterClick(e, itm, indx)}
                    ></input>
                    <label>{itm.metalname}</label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="FilterBarVertical_Categories">
          <div className="FilterBarVertical_header">
            <input
              type="checkbox"
              // value={isChecked3}
              key="4"
              onChange={(e) => wearTypeCheckAll(e)}
            ></input>
            <label>Shop by type</label>
          </div>
          <div className="FilterBarVertical_Cat_check">
            <ul>
              {weartype.map((itm, indx) => {
                return (
                  <li>
                    <input
                      type="checkbox"
                      id={itm.id}
                      checked={itm.isSelected}
                      onClick={(e) => handletypefilterClick(e, itm, indx)}
                    ></input>
                    <label>{itm.weartypenames}</label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="FilterBarVertical_Categories">
          <div className="FilterBarVertical_header">
            <input
              type="checkbox"
              // value={isChecked4}
              key="5"
              onChange={(e) => {
                styleCheckAll(e);
              }}
            ></input>
            <label>Shop by Style</label>
          </div>
          <div className="FilterBarVertical_Cat_check">
            <ul>
              {style.map((itm, indx) => {
                return (
                  <li>
                    <input
                      type="checkbox"
                      id={itm.id}
                      checked={itm.isSelected}
                      onClick={(e) => handlestylefilterClick(e, itm, indx)}
                    ></input>
                    <label>{itm.stylenames}</label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default FilterBarVertical;

import config from "../config.json";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Mobmenubar.css";
import goldpic from "./Images/header_menubar_gold.png";
import goldpic1 from "./Images/header_menubar_gold_popupright.png";
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdKeyboardArrowLeft } from "react-icons/md"

export default function Mobmenubar({ handleMenuviewClick, handleCategoryClick, handleStyleClick, handleTypeClick, handleMetalClick }) {
    const navigate = useNavigate();
    const baseurl = config.url;
    // const [catArray, setCatArray] = useState([]);
    const [goldrate, setGoldrate] = useState([]);
    const [catname, setCatName] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isPop, setIsPop] = useState(false);

    const handleGoldClick = (e) => {
        // localStorage.setItem("menuid", id);
        setIsVisible(current => !current);
    };

    const handleResponsiveShopClick = () => {
        navigate("/Shoppage");
        // setIsPop(false);
    };
    const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });

    useEffect(() => {
        const url = baseurl + "fetchmenulist";
        const req = {};
        const header = {};
        axios
            .post(url, req, header)
            .then((res) => {
                // if(res.data>0){
                setArray(res.data);
                // }
                console.log("menu array==>", res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //   console.log("arr==>"+array);
    const handleCatArrowClick = (e, itm) => {
        setIsPop(current => !current);
        localStorage.setItem("catname", itm.categoryname);
        localStorage.setItem("menuid1", itm.id);
        console.log("cat==>" + itm.id);
        setCatName(itm.categoryname);
    }

    const handleCatArrowClick1 = (e, itm) => {
        setIsPop(current => !current);
        localStorage.setItem("catname", itm.categoryname);
        localStorage.setItem("menuid1", itm.id);
        setCatName(itm.categoryname);
    }
    return (
        <>
            <div className="mobmenubar">
                <ul className="mobmenubar_ul">
                    {array.category.map((itm, indx) => {
                        return (
                            <li
                                className="mobmenubar_li"
                            ><div className="mobmenubar_li_cat">
                                    <label
                                        className="mobmenubar_title"
                                        onClick={(e) => handleCategoryClick(e, itm)}
                                    >
                                        {itm.categoryname}
                                    </label>
                                    <div className="mobmenubar_li_cat_arrow"
                                        onClick={(e) => handleCatArrowClick(e, itm)}
                                    >
                                        {/* <div className="mobmenubar_li_cat_arrow_inn" > */}
                                        <MdKeyboardArrowRight style={{ fontSize: "25", fontWeight: "500" }} />
                                        {/* </div> */}
                                    </div>
                                </div>

                            </li>
                        );
                    })}
                    {isPop ? <Popup array={array} data={catname} setIsPop={setIsPop} handleMenuviewClick={handleMenuviewClick}
                        handleMetalClick={handleMetalClick}
                        handleStyleClick={handleStyleClick}
                        handleTypeClick={handleTypeClick}
                    /> : null}
                    {/* <li className="mobmenubar_li">
                        <label className="mobmenubar_title" style={{paddingLeft:"5px"}} onClick={(e) => handleGoldClick(e)}>Gold Rate</label>
                        {isVisible ?
                            <div>
                                {array.goldrate.map((itm, indx) => {
                                    return (
                                        <div className="mobmenubar_title_goldrate">
                                            <label>Today's Gold Rate</label>
                                            <div className="mobmenubar_title_goldrate_row1">
                                                <label>22 KT(916)</label>
                                                <span>-</span>
                                                <label>Rs. {itm.goldrate}/g</label>
                                            </div>
                                            <div className="mobmenubar_title_goldrate_row1">
                                                <label>18 KT(750)</label>
                                                <span>-</span>
                                                <label>Rs. {itm.lowgoldrate}/g</label>
                                            </div>
                                            <div className="mobmenubar_title_goldrate_row1">
                                                <label>Updated on</label>
                                                <span>:</span>
                                                <label>{itm.date}</label>
                                            </div>
                                            <label>{itm.time}</label>
                                        </div>
                                    );
                                })}
                            </div>
                            : ""}
                    </li> */}
                </ul>
            </div>
        </>
    );
}

function Popup({ data, array, setIsPop, handleStyleClick, handleTypeClick, handleMetalClick }) {
    const baseurl = config.url;
    const navigate = useNavigate();
    const [isPop1, setIsPop1] = useState(true);
    const [subcat, setSubcat] = useState("");
    const [subcatname, setSubcatName] = useState("");
    // const [subcatlist, setSubCatList] = useState("menubar_popup_right");
    const handleSubClick = (e, name, id) => {
        setSubcat(name);
        localStorage.setItem("subcatid", id);
        // setSubCatList("menubar_popup_right_mobview")
        setMenuview("mobmenubar_popup_right_mobview")
    };
    const [menuview, setMenuview] = useState("mobmenubar_popup_right");

    const handleCatHideClick = (e) => {
        setIsPop(false);
    }
    const [itmId, setItmId] = useState("");
    const handleSubcatClick = (e, itm) => {
        if (itm.id == itmId) {
            setIsPop1(current => !current);
        }
        setSubcat(itm.subcategoryname);
        setItmId(itm.id);  
        console.log("sub" + itm.id);
        localStorage.setItem("subcatid1", itm.id);
        setSubcatName(itm.subcategoryname)
        // if (itm.id) {
        setMenuview("mobmenubar_popup_right_mobview")

        // }
    }

    // console.log("popup data==>",data)
    return (
        <>
            <div className={isPop1?"mobmenubar_popup_popup":"mobmenubar_popup"}>
                <div className={isPop1?"mobmenubar_popup_left_pupup":"mobmenubar_popup_left"}>
                    <ul>
                        <li className={isPop1?"mobmenubar_popup_left_category_popup":"mobmenubar_popup_left_category"}>
                            <MdKeyboardArrowLeft style={{ fontSize: "30" }} onClick={(e) => handleCatHideClick(e)} />
                            <label>{data}</label>
                        </li>
                        {array.subcat.map((itm, indx) => {
                            return (
                                <li className="mobmenubar_popup_left_li"
                                // onClick={(e) =>
                                //     handleSubClick(e, itm.subcategoryname, itm.id)
                                // }
                                // key={itm.id}
                                >
                                    <div className={isPop1?"mobmenubar_popup_left_li_inner_popup":"mobmenubar_popup_left_li_inner"} onClick={(e) => handleSubcatClick(e, itm)}>
                                        <div
                                            className={
                                                subcat == itm.subcategoryname
                                                    ? "mobmenubar_popup_left_title_bold"
                                                    : "mobmenubar_popup_left_title"
                                            }
                                        >
                                            {itm.subcategoryname}

                                        </div>
                                        {/* <div className="mobmenubar_popup_left_img"> */}
                                        <MdKeyboardArrowDown style={{
                                            fontSize: "20",transform: isPop1 && subcat == itm.subcategoryname ? 'rotate(180deg)' : '',
                                            transition: 'transform 150ms ease'
                                        }}
                                        //  onClick={(e) => handleSubcatClick(e, itm)}

                                        />
                                        {/* </div> */}
                                    </div>
                                    {itm.id == itmId && isPop1 ?
                                        //  {isPop1?
                                        <div className="mobmenubar_popup_left_li_inner1">
                                            <Subpopup array={array}
                                                handleMetalClick={handleMetalClick}
                                                handleStyleClick={handleStyleClick}
                                                handleTypeClick={handleTypeClick}
                                                subcatname={subcatname}
                                            />
                                        </div> : ""}
                                </li>
                            );
                        })}

                        {/* <div className="mobmenubar_popup_left_li_inner1"> */}
                        {/* {isPop1 ? */}
                        {/* {isPop1 ?
                            <Subpopup array={array}
                                handleMetalClick={handleMetalClick}
                                handleStyleClick={handleStyleClick}
                                handleTypeClick={handleTypeClick}
                                subcatname={subcatname}
                                setIsPop1={setIsPop1}
                            /> : ""} */}

                        {/* : ""}  */}
                        {/* </div> */}
                    </ul>
                </div>
            </div >
        </>
    );
}

function Subpopup({ handleMenuviewClick, array, handleStyleClick, handleTypeClick, handleMetalClick, subcatname, setIsPop1 }) {
    const navigate = useNavigate();
    // const handleStyleClick = (e, id) => {
    //     localStorage.setItem("styleid", id);
    //     navigate("/shoppage");
    //     window.location.reload();
    // };
    // const handleTypeClick = (e, id) => {
    //     localStorage.setItem("typeid", id);
    //     console.log("wear type ==> ", id);
    //     navigate("/shoppage");
    //     window.location.reload();
    // };
    // const handleMetalClick = (e, id) => {
    //     localStorage.setItem("metalid", id);
    //     navigate("/shoppage");
    //     console.log("Metal id ==> ", id);
    //     window.location.reload();
    // };
    const handlesubcatHideClick = () => {
        // setIsPop1(false)
    }

    return (
        <>
            <div className="mobmenubar_popup_right_outer">
                <div className="mobmenubar_popup_right">
                    {/* <div className="mobmenubar_popup_right_subcat">
                        <MdKeyboardArrowLeft style={{ fontSize: "30" }} onClick={(e) => handlesubcatHideClick(e)} />
                        <label>{subcatname}</label>
                    </div> */}
                    <div className="mobmenubar_popup_right_submenu">
                        <div className="mobmenubar_popup_right_one">
                            <h3>Shop By style</h3>
                            <ul>
                                {array.style.map((itm, indx) => {
                                    return (
                                        <li onClick={(e) => { handleStyleClick(e, itm.id); handleMenuviewClick() }}>
                                            {itm.stylenames}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="mobmenubar_popup_right_one">
                            <h3>Wearing Type</h3>
                            <ul>
                                {array.weartype.map((itm, indx) => {
                                    return (
                                        <li onClick={(e) => { handleTypeClick(e, itm.id); handleMenuviewClick() }}>
                                            {itm.weartypenames}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="mobmenubar_popup_right_one">
                            <h3>Shop By Metal</h3>
                            <ul>
                                {array.metal.map((itm, indx) => {
                                    return (
                                        <li onClick={(e) => { handleMetalClick(e, itm.id); handleMenuviewClick() }}>
                                            {itm.metalname}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

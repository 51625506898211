import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Shippingpage.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Shippingaddress from "../Components/Shippingaddress";
import AddressList from "../Components/AddressList";
import Whatsapp from "../Components/Whatsapp";
import config from "../config.json";
import Menubar from "../Components/Menubar";
import { useDispatch, useSelector } from "react-redux";

export default function Shippingpage() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [array1, setArray1] = useState([]);
  const [orderArray, setOrderArray] = useState([]);
  const [cartArr, setCartArr] = useState([]);
  const baseurl = config.url;
  // const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlist");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }
  var usrid = localStorage.getItem("loginid");
  console.log("local user id==>" + JSON.stringify(usrid));
  const user = useSelector(state => state.user)
  // console.log("usradd id==>", usrid);
  // console.log("redux usr id==>"+ JSON.stringify(user[0].id));
  const adreditid = localStorage.getItem("editadrid");
  // console.log("cart store==>"+ JSON.stringify(cart));
  const cart1 = JSON.stringify(cart);
  const handlePaymentpageClick = (e) => {
    console.log("edit addressid==>", adreditid);
    console.log("first name==>" + curraddress.firstname);
    if (isDisabled == true) {
      if (curraddress.firstname == undefined) {
        alert("Please Select Address!")
      } else {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        var usrid = localStorage.getItem("loginid");
        var adrid = localStorage.getItem("addressid");
        var total = localStorage.getItem("totalamount");
        const url = baseurl + "insertorder";
        const req = {
          createdon: date,
          shippedon: "",
          nearon: "",
          deliveredon: "",
          cancelledon: "",
          user: user[0].id,
          address: adrid,
          status: "Pending",
          amount: total,
          cartprd: cart1,
          id: user[0].id,
          email: user[0].email,
          password: user[0].password
        };
        const header = {

        };
        axios
          .post(url, req, header)
          .then((res) => {
            console.log(res.data);
            //  toast.info("You're added offer successfully !", { transition: Slide})

            setOrderArray(res.data);
            dispatch({ type: "setCart", payload: [] })
            localStorage.removeItem("totalamount");
            navigate("/Ordercompletedpage");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (isDisabled == false && adreditid !== "" && adreditid !== "0") {
      const url = baseurl + "editaddress";
      const req = {
        id: adreditid,
        firstname: curraddress.firstname,
        lastname: curraddress.lastname,
        address: curraddress.usraddress,
        place: curraddress.place,
        state: curraddress.state,
        postalno: curraddress.postalno,
        landmark: curraddress.landmark,
        phoneno: curraddress.phoneno,
         };
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          console.log(res.data);
          setArray1(res.data);
          // alert("Address sucessfully updated");
          const today = new Date();
          const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
          // var cart=localStorage.getItem("cartlength");
          // var a = JSON.parse(localStorage.getItem("cartitms"));
          console.log("cartArr==>" + cartArr)
          var usrid = localStorage.getItem("loginid");
          var adrid = localStorage.getItem("addressid");
          var total = localStorage.getItem("totalamount");
          const url = baseurl + "insertorder";
          const req = {
            createdon: date,
            shippedon: "",
            nearon: "",
            deliveredon: "",
            cancelledon: "",
            user: user[0].id,
            address: adreditid,
            status: "Pending",
            amount: total,
            cartprd: cart1,
            id: user[0].id, 
          email: user[0].email, 
          password: user[0].password
          };
          const header = {};
          axios
            .post(url, req, header)
            .then((res) => {
              console.log(res.data);
              setOrderArray(res.data);
              dispatch({ type: "setCart", payload: [] })
              navigate("/Ordercompletedpage");
              localStorage.removeItem("adreditid");
              localStorage.removeItem("totalamount");
            })
            .catch((err) => {
              console.log(err);
            });
          // navigate("/Ordercompletedpage");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const url = baseurl + "insertaddress";
      if (curraddress.firstname === '') {
        alert("First name field is empty!")
      } else if (curraddress.lastname === '') {
        alert("Last name field is empty!")
      } else if (curraddress.usraddress === '') {
        alert("Address field is empty!")
      } else if (curraddress.postalno === '') {
        alert("Postal number field is empty!")
      } else if (curraddress.phoneno === '') {
        alert("Phone number field is empty!")
      } else {
        const req = {
          id: user[0].id,
          firstname: curraddress.firstname,
          lastname: curraddress.lastname,
          address: curraddress.usraddress,
          place: curraddress.place,
          landmark: curraddress.landmark,
          state: curraddress.state,
          postalno: curraddress.postalno,
          phoneno: curraddress.phoneno,
        };
        const header = {};
        axios
          .post(url, req, header)
          .then((res) => {
            console.log(res.data);
            setArray1(res.data);
            // alert("Address sucessfully inserted");
            localStorage.setItem("adrsid", res.data.insertId);
            console.log("inseted address id==>", res.data.insertId);
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
            // var cart=localStorage.getItem("cartlength");
            // var a = JSON.parse(localStorage.getItem("cartitms"));
            console.log("cartArr==>" + cartArr)
            var usrid = localStorage.getItem("loginid");
            var adrid = localStorage.getItem("addressid");
            var total = localStorage.getItem("totalamount");
            const url = baseurl + "insertorder";
            const req = {
              createdon: date,
              shippedon: "",
              nearon: "",
              deliveredon: "",
              cancelledon: "",
              user: user[0].id,
              address: res.data.insertId,
              status: "Pending",
              amount: total,
              cartprd: cart1,
              id: user[0].id, 
          email: user[0].email, 
          password: user[0].password
            };
            const header = {};
            axios
              .post(url, req, header)
              .then((res) => {
                console.log(res.data);
                setOrderArray(res.data);
                dispatch({ type: "setCart", payload: [] })
                localStorage.removeItem("totalamount");
                navigate("/Ordercompletedpage");
              })
              .catch((err) => {
                console.log(err);
              });

          })

          .catch((err) => {
            console.log(err);
          });
      }
    }


    // const url1 = baseurl + "ordercart";
    // const req1 = {
    //   uid: usrid,
    // };
    // const header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     console.log("cart clear==>", res.data);
    //     setCartArr(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const [usraddress, setUsrAddress] = useState({});
  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [], "address": [] });
  const [addressArr, setAddressArr] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "getaddress";
    const req = { id: user[0].id };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setArray(res.data);
        setUsrAddress(res.data);
        // console.Console("")
        // setCurrAddress(res.data.address[0].length>0 ?res.data.address[0]:[] )
        if (res.data.address.length > 0) {
          let temp = [...res.data.address];
          for (const i of temp) {
            for (let j = 0; j < temp.length; j++) {
              temp[j].isSelected = false;
            }
            temp[0].isSelected = true;
          }
          console.log("temp==>", temp)
          setAddressArr(temp);
          setCurrAddress(res.data.address[0])
          localStorage.setItem("addressid", res.data.address[0].id);
          console.log("address useEffect id==>" + res.data.address[0].id)
        } else {
          isSelected(true)

        }

      })
      .catch((err) => {
        console.log(err);
        // isSelected(true)
        // alert("add")
      });
  }, []);
  localStorage.setItem("useradd", JSON.stringify(usraddress));
  console.log("user address2==>", JSON.stringify(usraddress));
  const [currentaddress, setCurrentAddress] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const handleEditAddressClick = (e, itm) => {
    e.preventDefault();
    const id = itm.id;
    setCurrAddress(itm);
    setIsDisabled(false);
    localStorage.setItem("editadrid", id);
    console.log("edit id==>", id);
    // console.log("edit address==>",itm.id)
  };
  const handleAddressClick = (e, item, indx) => {
    e.preventDefault();
    localStorage.setItem("addressid", JSON.stringify(item.id));
    console.log("user id==>", JSON.stringify(item.id));
    console.log("user address click==>", JSON.stringify(item));
    setCurrentAddress(item);
    setCurrAddress(item);
    setIsDisabled(true);

  };

  const handleAddAddressClick = (e) => {
    e.preventDefault();
    const id = 0;
    localStorage.setItem("editadrid", id);
    setCurrAddress({
      firstname: "",
      lastname: "",
      usraddress: "",
      place: "",
      state: "",
      postalno: "",
      landmark: "",
      phoneno: ""
    });
    setIsDisabled(false);
  };

  const changeFirstname = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.firstname = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changeLastname = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.lastname = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changeAddress = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.usraddress = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changePlace = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.place = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changeState = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.state = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changePostalno = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.postalno = e.target.value;
    setCurrAddress(temp);
    // console.log("temp==>", temp);
  };
  const changeLandmark = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.landmark = e.target.value;
    setCurrAddress(temp);
    console.log("temp==>", temp);
  }

  const changePhoneno = (e) => {
    e.preventDefault();
    const temp = { ...curraddress };
    temp.phoneno = e.target.value;
    setCurrAddress(temp);
    console.log("temp==>", temp);
  }

  const [curraddress, setCurrAddress] = useState({});
  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  const [isSelected, setIsSelected] = useState(false);

  const onChangeValue = (e, item) => {
    if (e.target.name === "add") {
      setIsSelected(true)
      let temp = [...addressArr]
      for (const i of temp) {
        i.isSelected = false
      }
      const id = 0;
      localStorage.setItem("editadrid", id);
      setCurrAddress({
        firstname: "",
        lastname: "",
        usraddress: "",
        place: "",
        state: "",
        postalno: "",
        landmark: "",
        phoneno: ""
      });
      setIsDisabled(false);
    } else {
      let temp = [...addressArr]
      for (const i of temp) {
        i.isSelected = false
      }
      temp[e.target.value].isSelected = true;
      console.log("array iterate==>" + JSON.stringify(temp));
      setIsSelected(false);
      setAddressArr(temp)
      localStorage.setItem("addressid", JSON.stringify(item.id));
      setCurrentAddress(item);
      setCurrAddress(item);
      setIsDisabled(true);

    }
  }

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
  }

  return (
    <>
      <div className="shippingpage">
        <div className="shippingpage_header">
          <Header
            handleCartClick={handleCartClick}
            handleLoginClick={handleLoginClick}
            handleHomeClick={handleHomeClick}
            handleShopClick={handleShopClick}
            handleWishListClick={handleWishListClick}
            handleContactClick={handleContactClick}
            isNeeded={false}
            handleMenuClick={handleMenuClick}
            isPop={isPop}
            array={array}
          />
        </div>
        {/* {isPop ?
          <div className="shippingpage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null} */}
        <Subbanner title={"Shipping"} />
        <AddressList
          handleAddressClick={handleAddressClick}
          handleAddAddressClick={handleAddAddressClick}
          handleEditAddressClick={handleEditAddressClick}
          array={addressArr}
          index={Option}
          isDisabled={isDisabled}
          isSelected={isSelected}
          onChangeValue={onChangeValue}
        />
        <Shippingaddress
          handlePaymentpageClick={handlePaymentpageClick}
          changeFirstname={changeFirstname}
          changeLastname={changeLastname}
          changeAddress={changeAddress}
          changePlace={changePlace}
          changeState={changeState}
          changePostalno={changePostalno}
          changeLandmark={changeLandmark}
          changePhoneno={changePhoneno}
          curraddress={curraddress}
          setCurrAddress={setCurrAddress}
          isDisabled={isDisabled}
        />
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
        <div className="homepage_whatsapp">
          <Whatsapp />
        </div>
      </div>
    </>
  );
}

import config from "../config.json";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Menubar.css";
import goldpic from "./Images/header_menubar_gold.png";
import goldpic1 from "./Images/header_menubar_gold_popupright.png";

export default function Menubar({ array, handleMenuviewClick }) {
  const navigate = useNavigate();
  const baseurl = config.url;
  // const [catArray, setCatArray] = useState([]);
  const [goldrate, setGoldrate] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isPop, setIsPop] = useState(true);
  // const [isChecked, setIsChecked] = useState(false);
  // useEffect(() => {
  //   // const url = baseurl + "fetchcategory";
  //   // const req = {};
  //   // const header = {};
  //   // axios
  //   //   .post(url, req, header)
  //   //   .then((res) => {
  //   //     if(res.data>0){
  //   //     setCatArray(res.data);
  //   //     }
  //   //     console.log("category array==>", catArray);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });

  //   // const url1 = baseurl + "fetchratehistory";
  //   // const req1 = {};
  //   // const header1 = {};
  //   // axios
  //   //   .post(url1, req1, header1)
  //   //   .then((res) => {
  //   //     setGoldrate(res.data);
  //   //     console.log("gold rate==>", res.data);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });
  // }, []);
  // console.log("menu array==>" + JSON.stringify(array.category))
  const handlemenuClick = (e, id) => {
    localStorage.setItem("menuid", id);
  };

  const handleResponsiveShopClick = () => {
    navigate("/Shoppage");
    // setIsPop(false);
  };

  return (
    <>
      <div className="menubar">
        <ul className="menubar_ul">
          {array.category.map((itm, indx) => {
            return (
              <li
                className="menubar_li"
                onClick={(e) => handlemenuClick(e, itm.id)}
              >
                <label
                  className="menubar_title"
                // onClick={() => handleResponsiveShopClick()}
                >
                  {itm.categoryname.toUpperCase()}
                </label>
                {isPop ? <Popup array={array} data={itm.categoryname} handleMenuviewClick={handleMenuviewClick} /> : null}
              </li>
            );
          })}

          <li className="menubar_li">
            <label className="menubar_title">GOLD RATE</label>
            {/* <Popup  /> */}
            {array.goldrate.map((itm, indx) => {
              return (
                <div className="menubar_title_goldrate">
                  <label>Today's Gold Rate</label>
                  <div className="menubar_title_goldrate_row1">
                    <label>22 KT(916)</label>
                    <span>-</span>
                    <label>Rs. {itm.goldrate}/g</label>
                  </div>
                  <div className="menubar_title_goldrate_row1">
                    <label>18 KT(750)</label>
                    <span>-</span>
                    <label>Rs. {itm.lowgoldrate}/g</label>
                  </div>
                  <div className="menubar_title_goldrate_row1">
                    <label>Updated on</label>
                    <span>:</span>
                    <label>{itm.date}</label>
                  </div>
                  <label>{itm.time}</label>

                  {/* {goldrate.map((itm,indx)=>{
                  return(
                <label>{itm.goldrate}</label>
                )
                })} */}
                </div>
              );
            })}
          </li>
        </ul>
      </div>
    </>
  );
}

function Popup({ data, array, handleMenuviewClick }) {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [isPop, setIsPop] = useState(true);
  const [subcat, setSubcat] = useState("");
  // const [subcatlist, setSubCatList] = useState("menubar_popup_right");
  const handleSubClick = (e, name, id) => {
    setSubcat(name);
    localStorage.setItem("subcatid", id);
    // setSubCatList("menubar_popup_right_mobview")
    setMenuview("menubar_popup_right_mobview")
  };
  const handleStyleClick = (e, id) => {
    localStorage.setItem("styleid", id);
    navigate("/shoppage");
    // window.location.reload();
  };
  const handleTypeClick = (e, id) => {
    localStorage.setItem("typeid", id);
    console.log("wear type ==> ", id);
    navigate("/shoppage");
  };
  const handleMetalClick = (e, id) => {
    localStorage.setItem("metalid", id);
    navigate("/shoppage");
    console.log("Metal id ==> ", id);
  };
  const [menuview, setMenuview] = useState("menubar_popup_right");
  // console.log("popup data==>",data)
  return (
    <>
      <div className="menubar_popup">
        <div className="menubar_popup_left">
          <ul>
            {array.subcat.map((itm, indx) => {
              return (
                <li
                  onClick={(e) =>
                    handleSubClick(e, itm.subcategoryname, itm.id)
                  }
                >
                  <div className="menubar_popup_left_img">
                    <img src={goldpic} />
                  </div>
                  <div
                    className={
                      subcat == itm.subcategoryname
                        ? "menubar_popup_left_title_bold"
                        : "menubar_popup_left_title"
                    }
                  >
                    {itm.subcategoryname}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className={menuview}> */}
        <div className="menubar_popup_right">
          <div className="menubar_popup_right_one">
            <h3>Shop By style</h3>
            <ul>
              {array.style.map((itm, indx) => {
                return (
                  <li onClick={(e) => { handleStyleClick(e, itm.id); handleMenuviewClick() }}>
                    <div className="menubar_popup_right_one_img">
                      <img src={goldpic1}></img>
                    </div>
                    {itm.stylenames}
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="menubar_popup_right_one">
              <h3> </h3>
              <ul>
                {right.cat2.map((itm, indx) => {
                  return (
                    <li>
                      <div className="menubar_popup_right_one_img">
                        <img src={goldpic1}></img>
                      </div>
                      {itm}
                    </li>
                  );
                })}
  
              </ul>
            </div> */}
          <div className="menubar_popup_right_one">
            <h3>Wearing Type</h3>
            <ul>
              {array.weartype.map((itm, indx) => {
                return (
                  <li onClick={(e) => { handleTypeClick(e, itm.id); handleMenuviewClick() }}>
                    <div className="menubar_popup_right_one_img">
                      <img src={goldpic1}></img>
                    </div>
                    {itm.weartypenames}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="menubar_popup_right_one">
            <h3>Shop By Metal</h3>
            <ul>
              {array.metal.map((itm, indx) => {
                return (
                  <li onClick={(e) => { handleMetalClick(e, itm.id); handleMenuviewClick() }}>
                    <div className="menubar_popup_right_one_img">
                      <img src={goldpic1}></img>
                    </div>
                    {itm.metalname}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

import React from "react";
import "./Filterbar.css";
import { MdFilterListAlt } from "react-icons/md";
import { IoCaretBackOutline } from "react-icons/io5";
import { AiTwotoneHome } from "react-icons/ai";
import Mobmenubar from "./Mobmenubar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Filterbar({ handleCategoryClick, isPopup, handleMobFilterClick, handleStyleClick, handleTypeClick, handleMetalClick }) {
    // const location = useLocation();
    // const referer = location.state && location.state.referer;
    const navigate = useNavigate();
    const handleBackPageClick = (e) => {
        // navigate("/")
        window.scrollTo(0, 0);
        window.history.back()
    }

    const handleBackHomeClick = () => {
        navigate("/");
    }

    return (
        <>
            {isPopup ? <Mobmenubar handleCategoryClick={handleCategoryClick}
                handleMetalClick={handleMetalClick}
                handleStyleClick={handleStyleClick}
                handleTypeClick={handleTypeClick}
            /> : ""}
            <div className="filterbar">
                <div className="filterbar_row1"
                    onClick={(e) => handleBackPageClick(e)}
                >
                    <IoCaretBackOutline style={{ color: "#f4579b", fontSize: "20px", fontWeight: "650" }} />
                    <label>Back</label>
                </div>
                <div className="filterbar_row_boarder1">
                    {/* <IoIosArrowBack/>
                    <label>Back</label> */}
                </div>
                <div className="filterbar_row" onClick={(e) => handleMobFilterClick(e)}>
                    <MdFilterListAlt style={{ color: "#f4579b", fontSize: "20px" }} />
                    <label>FILTER BY</label>
                </div>
                <div className="filterbar_row_boarder2"></div>
                <div className="filterbar_row3" onClick={(e) => handleBackHomeClick(e)}>
                    <AiTwotoneHome style={{ color: "#f4579b", fontSize: "20px" }} />
                    <label>Home</label>
                </div>
            </div>
        </>
    )
}
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Subbanner from "../Components/Subbanner";
import Orderlist from "../Components/Orderlist";
import Footer from "../Components/Footer";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config.json";
import "./Orderlistpage.css";
import Menubar from "../Components/Menubar";
import Whatsapp from "../Components/Whatsapp";
import Loader from "../Components/Loader";
import { useDispatch, useSelector } from "react-redux";

export default function Orderlistpage() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
    window.scrollTo(0, 0);

  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }
  const handleOrderDetailPageClick = (e, itm) => {
    localStorage.setItem("ordid", itm.id);
    console.log("order id==>", itm.id);
    navigate("/Orderdetailpage");
  };
  var usrid = localStorage.getItem("loginid");
  console.log("user id==>" + user[0].id);
  const [show, setShow] = useState(false);
  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [], "orderlist": [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    setShow(true);
    const url = baseurl + "getorders";
    const req = {
      uid: user[0].id
    };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        // console.log(res.data);
        setArray(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
  }

  return (
    <>
      <div className="orderlistpage_header">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuClick}
          handleContactClick={handleContactClick}
          isNeeded={false}
          isPop={isPop}
          array={array}
        />
      </div>
      {/* {isPop ?
        <div className="orderlistpage_menubar_mobview">
          <Menubar
            array={array}
          />
        </div>
        : null} */}
      <Loader show={show} setShow={setShow} />

      <Subbanner label="Order List" title={"Order List"} />
      <div className="componentorderlist">
        <Orderlist
          handleOrderDetailPageClick={handleOrderDetailPageClick}
          array={array}
        />
      </div>
      <Footer
        handleSignupClick={handleSignupClick}
        handleProfilePageClick={handleProfilePageClick}
        handleOrderListClick={handleOrderListClick}
      // handleOrderDetailPageClick={handleOrderDetailPageClick}
      />
      <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Menubar from "../Components/Menubar";
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Whatsapp from "../Components/Whatsapp";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import BrandList from "../Components/BrandList";
import Relatedproducts from "../Components/Relatedproducts";
import Productthumb from "../Components/Productthumb";
import Loader from "../Components/Loader";
import ProductDescription from "../Components/ProductDescription";
import "./Singleproductpage.css";
import Cartpage from "./Cartpage";
import { useEffect, useState } from "react";
import Filterbar from "../Components/Filterbar";
import config from "../config.json";

export default function Singleproductpage() {
  // const dispatch=useDispatch();

  const baseurl = config.url;
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }

  // setAddCart({...addcart,a}); 

  const [show, setShow] = useState(false);
  const [Warning, setWarning] = useState(false);
  const [show1, setShow1] = useState(true);
  var uid = localStorage.getItem("prdid");
  const [name,setNam]=useState("");
  const [msg,setMsg]=useState("");
  const [array, setArray] = useState({ "details": [], "images": []});
  useEffect(() => {
    window.scrollTo(0,0);
    setShow(true)
    window.scrollTo(0,0);
    // const url=""
    const url = baseurl + "singleproductfetch";
    const req = { id: uid };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        if (res.data) {
          // const t=JSON.parse(JSON.stringify(res.data));
          // console.log("details array==>"+t);

          setArray(res.data);
          setNam(res.data.details[0].prdname)
          setMsg(res.data.details[0].prdcode)

          // localStorage.setItem("category", res.data[0].prdcategory);
          // localStorage.setItem("style", res.data[0].prdstyle);
          // localStorage.setItem("metal", res.data[0].prdmetal);
          // setArray(res.data.details)
          // setSubimg(res.data.image[0])
          // setSubimg1(res.data.image[1])
          // setSubimg2(res.data.image[2])
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // const url1 = baseurl + "fetchsubimages";
    // const req1 = { id: uid };
    // const header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     console.log("sub img==>" + JSON.stringify(res.data[0]));
    //     setSubimg(res.data[0].image);
    //     setSubimg1(res.data[1].image);
    //     setSubimg2(res.data[2].image);
    //   })
    //   .catch();
  }, []);

  const [catArray, setCatArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });

  useEffect(() => {
    const url = baseurl + "fetchmenulist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        // if(res.data>0){
        setCatArray(res.data);
        // }
        console.log("menu array==>", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [relatedArray, setRelatedArray] = useState([]);
  var cat = localStorage.getItem("prdcategory");
  var st = localStorage.getItem("prdstyle");
  var met = localStorage.getItem("prdmetal");
  var subcat=localStorage.getItem("prdsubcat");
  var type=localStorage.getItem("prdtype");
  
  useEffect(() => {
    // const url=""
    const url = baseurl + "getrelatedproducts";
    const req = {
      category: cat,
      style: st,
      metal: met,
      subcategory:subcat,
    };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        if(res.data.length>0){
        console.log(res.data);
        
        setRelatedArray(res.data);
      }
        // localStorage.setItem("prdid",);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  const [isPopup, setIsPopup] = useState(false);
  const handleMobFilterClick = (e) => {
    setIsPopup(current => !current);
    localStorage.removeItem("menuid1");
    localStorage.removeItem("subcatid1");
    localStorage.removeItem("styleid1");
    localStorage.removeItem("typeid1");
    localStorage.removeItem("metalid1");
  }
  // const [isSelected,setIsSelected]=useState(false);
  const handleCategoryClick = (e, itm) => {
    localStorage.setItem("menuid1", itm.id);
    console.log("cat==>" + itm.id);
    navigate("/shoppage");
  }

  const handleStyleClick = (e, id) => {
    localStorage.setItem("styleid1", id);
    // const mid = localStorage.getItem("menuid1");
    // console.log("menu id==>", mid);
    // const sid = localStorage.getItem("subcatid1");
    navigate("/shoppage");
    
};
const handleTypeClick = (e, id) => {
    localStorage.setItem("typeid1", id);
    console.log("wear type ==> ", id);
    // const mid = localStorage.getItem("menuid1");
    // console.log("menu id==>", mid);
    // const sid = localStorage.getItem("subcatid1");
    navigate("/shoppage");
    
};
const handleMetalClick = (e, id) => {
    localStorage.setItem("metalid", id);
    const mid = localStorage.getItem("menuid");
    // console.log("menu id==>", mid);
    const sid = localStorage.getItem("subcatid");
    navigate("/shoppage");
   
};

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
  }
  

  return (
    <>
    {/* <HelixLoader show={show} setShow={setShow}/> */}
    <div className="singleproductpage">
    <div className="singleproductpage_header">
      <Header
        handleCartClick={handleCartClick}
        handleLoginClick={handleLoginClick}
        handleHomeClick={handleHomeClick}
        handleShopClick={handleShopClick}
        handleWishListClick={handleWishListClick}
        handleContactClick={handleContactClick}
        isNeeded={false}
        handleMenuClick={handleMenuClick}
        isPop={isPop}
        array={catArray}
        />
        </div>
       {/* size={cart.length}    */}
      {/* {isPop ?
        <div className="singleproductpage_menubar_mobview">
          <Menubar
            array={catArray}
          />
        </div>
        : null} */}
      <Subbanner title={"Product Details"} />
      <Loader show={show} setShow={setShow} />
      <Productthumb array={array}
      name={name}
      msg={msg}
      />
      <ProductDescription array={array} />
      <div className="loaderpage">
        {/* <Loader show={show} /> */}
      </div>
      <Relatedproducts relatedArray={relatedArray}/>
      <BrandList />
      
      <Footer
        handleSignupClick={handleSignupClick}
        handleProfilePageClick={handleProfilePageClick}
        handleOrderListClick={handleOrderListClick}
        handleOrderDetailPageClick={handleOrderDetailPageClick}
      />
      {/* <div className="singleproductpage_whatsapp">
        <Whatsapp />
      </div> */}
      <div className="singleproductpage_filterbar_bottom">
          <Filterbar handleCategoryClick={handleCategoryClick}
            handleMobFilterClick={handleMobFilterClick}
            isPopup={isPopup}
            handleMetalClick={handleMetalClick}
            handleStyleClick={handleStyleClick}
            handleTypeClick={handleTypeClick}
          />
        </div>
        </div>
      {
        Warning && <div className="warning">Item is already added to your cart</div>
      }
    </>
  );
}

import react from "react-icons";
import "./Whatsapp.css";
import {BsWhatsapp} from "react-icons/bs";

export default function Whatsapp(){
        return(
        <>
        <div className="whatsapp">
            <div className="whatsapp_in">
            <div className="whatsapp_inner">
                <a 
                href="https://wa.me/918086449916"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
                >
                    <BsWhatsapp 
                    style={{color:"white",fontSize:"22"}} 
                    />
                </a>
            </div>
            </div>
        </div>
        </>
    )
}
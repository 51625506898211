import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Loginpage.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Login from "../Components/Login";
import Signup from "../Components/Signup";
import Loader from "../Components/Loader";
import config from "../config.json";
import Menubar from "../Components/Menubar";
import Whatsapp from "../Components/Whatsapp";

export default function Loginpage() {
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }

  const baseurl = config.url;
  const [show,setShow]=useState(false);

  const [isPop, setIsPop] = useState(false);
  const [menulist, setMenuList] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "fetchfilterlist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setMenuList(res.data);

        // localStorage.setItem("array"+res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };
  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
}


  return (
    <>
      <div className="loginpage">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuClick}
          handleContactClick={handleContactClick}
          isNeeded={false}
          isPop={isPop}
          array={menulist}
        />
        {/* {isPop ?
          <div className="loginpage_menubar_mobview">
            <Menubar
              array={menulist}
            />
          </div>
          : null} */}
        <Subbanner title={"Login"} />
        <Loader show={show} />
        <div className="loginpage_logsign">
          {/* <div className="loginpage_logsign_login"> */}
            <Login />
          {/* </div> */}
                  </div>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
      </div>
      <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Loginpage.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Login from "../Components/Login";
import Signup from "../Components/Signup";
import Loader from "../Components/Loader";
import config from "../config.json";
import Menubar from "../Components/Menubar";

export default function Loginpage() {
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }

  const baseurl = config.url;
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [phone, setPhone] = useState("");
  const [array, setArray] = useState([]);
  const [show, setShow] = useState(false);
  const handleRegisterClick = (e) => {

    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (fname == "" && lname == "" && email == "" && password == "") {
      alert("Fields are empty");
    } else if (fname == "") {
      alert("Firstname empty");
    } else if (lname == "") {
      alert("Lastname empty");
    } else if (email == "") {
      alert("Email field empty");
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      // isValid = false;
      alert("Please enter valid email address.");
      // alert("Email field empty");
    } else if (phone == "") {
      alert("Phone number field is empty");
    } else if (phone.length !== 10) {
      alert("Please Enter valid phone number");
    } else if (password == "") {
      alert("Password field is empty");
    } else if (repassword == "") {
      alert("Re-password field is empty");
    } else if (password !== repassword) {
      alert("Repassword not matching");
    } else {
      setShow(true);
      const url = baseurl + "signup";
      const req = {
        firstname: fname,
        lastname: lname,
        email: email,
        password: password,
        phoneno: "",
      };
      const header = {};
      axios
        .post(url, req, header)
        .then((res) => {
          console.log(res.data);
          setShow(false);
          setArray(res.data);
          alert("successfully registered");
          // window.location.reload();
          setFName("");
          setLName("");
          setEmail("");
          setPassword("");
          setRepassword("");
          setPhone("");
          navigate("/Loginpage");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [isPop, setIsPop] = useState(false);
  const [catArray, setMenuList] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "fetchfilterlist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setMenuList(res.data);

        // localStorage.setItem("array"+res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };


  return (
    <>
      <div className="loginpage">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuClick}
          isNeeded={false}
          isPop={isPop}
          array={catArray}
        />
        {/* {isPop ?
          <div className="loginpage_menubar_mobview">
            <Menubar
              array={menulist}
            />
          </div>
          : null} */}
        <Subbanner title={"Create Account"} />
        <Loader show={show} />
          {/* <div className="loginpage_logsign_signup"> */}
            <Signup
              handleRegisterClick={handleRegisterClick}
              fname={fname}
              lname={lname}
              email={email}
              password={password}
              repassword={repassword}
              setFName={setFName}
              setLName={setLName}
              setEmail={setEmail}
              setPassword={setPassword}
              setRepassword={setRepassword}
              phone={phone}
              setPhone={setPhone}
            />
          {/* </div> */}
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
      </div>
    </>
  );
}

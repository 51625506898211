import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Forgotpassword from "../Components/Forgotpassword";
import Subbanner from "../Components/Subbanner";
import Whatsapp from "../Components/Whatsapp";
import "./Ordercompletedpage.css";
import Menubar from "../Components/Menubar";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../config.json";
// import emailjs from 'emailjs-com';
import emailjs from "@emailjs/browser";

export default function Forgotemailpage() {
    const form = useRef();
    const baseurl = config.url
    const navigate = useNavigate();
    const handleShopClick = () => {
        navigate("/Shoppage");
    };
    const handleCartClick = () => {
        navigate("/CartPage");
    };
    const handleLoginClick = () => {
        navigate("/Loginpage");
    };
    const handleHomeClick = () => {
        navigate("/");
    };
    const handleSignupClick = () => {
        navigate("/Loginpage");
    };
    const handleProfilePageClick = () => {
        navigate("/Profilepage");
    };
    const handleOrderListClick = () => {
        navigate("/Orderlistpage");
    };
    const handleOrderDetailPageClick = () => {
        navigate("/Orderdetailpage");
    };
    const handleWishListClick = () => {
        navigate("/wishlistpage");
    }

    const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
    useEffect(() => {
        window.scrollTo(0, 0);
        // const url = baseurl + "fetchfilterlist";
        //   const req = {};
        //   const header = {};
        //   axios
        //     .post(url, req, header)
        //     .then((res) => {
        //       console.log("fetch filter==>", res.data);
        //         setArray(res.data);

        //       // localStorage.setItem("array"+res.data);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
    }, [])

    const [email, setEmail] = useState("")
    const [disabled, setDisabled] = useState(false);

    const handleOtpBtnClick = (e) => {

        function betweenRandomnumber(min, max) {
            return Math.floor(
                Math.random() * (max - min + 1) + min
            )
        }
        var otp = betweenRandomnumber(100000, 999999);
        // const email1="jinu@gmail.com";
        const url = baseurl + "otpgenerate";
        var data = {
            email: email,
            otp: otp
        };
        const header = {};
        const subject = "OTP from Maharaja Gold & Diamonds";
        let message = otp;
        var body = `Your OTP is ${otp}`;
        const url1 = baseurl + "validateEmail";
        var data1 = {
            email: email
        };
        const header1 = {};
axios
.post(url1,data1,header1)
.then((res)=>{
    if(res.data.length>0){
        window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: "maharajajewellery12@gmail.com",
            Password: "942A4B188CF8D2B74B4D28E4CFE152A83A76",
            To: email,
            From: 'maharajajewellery12@gmail.com',
            Subject: subject,
            Body: body
        }).then(
            message=>{
                if(message=="OK"){
                    alert("OTP send to your email "+email);
            axios
                .post(url, data, header)
                .then((res) => {
                    navigate("/forgotpasswordpage");
                })
                .catch((err) => {
                    console.log(err);
                })
            }else{
                alert("Email address verified....")
            }
        
            }
        );
    }else{
        setDisabled(true);
    }
    })

    }
    const handleOtpClick = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setDisabled(false)
    };

    const [isPop, setIsPop] = useState(false);
    const handleMenuClick = () => {
        setIsPop(current => !current);
    };

    const handleContactClick = (e) => {
        // contact1.current.focus();
        window.scrollTo(0, 10000);
    }

    return (
        <>
            <Header
                handleCartClick={handleCartClick}
                handleLoginClick={handleLoginClick}
                handleHomeClick={handleHomeClick}
                handleShopClick={handleShopClick}
                handleWishListClick={handleWishListClick}
                handleMenuClick={handleMenuClick}
                isNeeded={false}
                handleContactClick={handleContactClick}
            />
            {/* {isPop ?
          <div className="ordercompletedpage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null} */}
            <Subbanner label="Forgot Password" title={"Forgot Password"} />
            {/* <OrderCompleted handleShopClick={handleShopClick} /> */}
            {/* <form method="post"> */}
            <div className="form">
                <Forgotpassword
                    header="Forgot Password"
                    label1="Email Id"
                    paceholder1="Enter Email Id"
                    label3="Enter Valid Email Id"
                    button="Generate OTP"
                    handleOtpBtnClick={handleOtpBtnClick}
                    otp={email}
                    handleOtpClick={handleOtpClick}
                    disabled={disabled}
                />
            </div>
            {/* </form> */}
            <Footer
                handleSignupClick={handleSignupClick}
                handleProfilePageClick={handleProfilePageClick}
                handleOrderListClick={handleOrderListClick}
                handleOrderDetailPageClick={handleOrderDetailPageClick}
            />
            <div className="homepage_whatsapp">
                <Whatsapp />
            </div>
        </>
    );
}

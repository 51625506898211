import truck from "./Images/Offerlist-truck.png";
import tf from "./Images/Offerlist-tf.png";
import bb from "./Images/Offerlist-bb.png";
import tfs from "./Images/Offerlist-tfs.png";
import image1 from "./Images/a1.png";
import image2 from "./Images/a2.png";
import image3 from "./Images/a3.png";
import image4 from "./Images/a4.png";

import "./Offerlist.css";

function Offerlist() {
  return (

    <div className="offerlist-WhatShopexOffers">
      <div className="Offerlist_outercover">
        <div className="Offerlist-l1">
          <label> Be With Us!</label>
        </div>

        <div className="Offerlist-img-cover">
          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image1} />
              </div>

              <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">ADVANCE BOOKING</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    WE PROVIDE AN ADVANCE BOOKING FOR GOLD BY THAT DATE
                  </label>
                  <label className="Offerlist-under-lbl-img111_blank">
                    {/* consectetur adipiscing elit */}
                  </label>
                  {/* <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image2} />
              </div>

              <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">EXCHANGE AVAILABLE</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    WE PROVIDE AN EXCHANGE FOR YOUR GOLD TO NEW GOLD 
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                  ACCORDING TO THE CURRENT GOLD RATE
                  </label>
                  {/* <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image3} />
              </div>

              <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">FAST DELIVERY</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    WE PROVIDE FAST AND SECURE DELIVERY ACROSS KERALA
                  </label>
                  <label className="Offerlist-under-lbl-img111_blank">
                  </label>
                  {/* <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="Offerlist-hghhfgfgfhgf">
            <div className="Offerlist-orderlisttttt">
              <div className="Offerlist-ohyfffkgfkcgt-img-truck">
                <img src={image4} />
              </div>

              <div className="Offerlist-oc_img-lbl">
                <label className="Offerlist-img-hndgg">ONLINE SUPPORT</label>
                <div className="Offerlist-under-lbl-img">
                  <label className="Offerlist-under-lbl-img111">
                    WE PROVIDE AN ONLINE SUPPORT FROM 9:30 AM TO 6:00 PM 
                  </label>
                  <label className="Offerlist-under-lbl-img111">
                  ON ALLA DAYS
                  </label>
                  {/* <label className="Offerlist-under-lbl-img111">
                    massa purus gravida
                  </label> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Offerlist;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Menubar from "../Components/Menubar";
import Wishlist from "../Components/Wishlist";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Whatsapp from "../Components/Whatsapp";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import "./Wishlistpage.css";

export default function Wishlistpage() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleCartClick = () => {
    navigate("/CartPage");
  };

  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = baseurl + "fetchfilterlist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log("fetch filter==>", res.data);
        setArray(res.data);

        // localStorage.setItem("array"+res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };
  const handleWishListClick =()=>{
    window.location.reload()
  }

  return (
    <>
    <div className="wishlistpage_header">
      <Header
        handleLoginClick={handleLoginClick}
        handleHomeClick={handleHomeClick}
        handleShopClick={handleShopClick}
        handleCartClick={handleCartClick}
        handleWishListClick={handleWishListClick}
        handleMenuClick={handleMenuClick}
        isPop={isPop}
        array={array}
      />
      </div>
      {/* {isPop ?
        <div className="wishlistpage_menubar_mobview">
          <Menubar
            array={array}
          />
        </div>
        : null} */}
      <Subbanner title={"Wishlist"} />
      <div className="wishlistpage_wishcomponent">
        <Wishlist
        // handleAddtoCartClick={handleAddtoCartClick}
        // array={cartitm}
        // setCart={setCartItm}
        />
      </div>
      <Footer
        handleSignupClick={handleSignupClick}
        handleProfilePageClick={handleProfilePageClick}
        handleOrderListClick={handleOrderListClick}
        handleOrderDetailPageClick={handleOrderDetailPageClick}
      />
      <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
    </>
  );
}

import "./Header.css";
import React,{useRef} from "react";
import { BsFillBagCheckFill, BsFillCupFill } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import Header_phone from "./Images/Header_phone.png";
import Header_envelope from "./Images/Header_envelope.png";
import Header_user from "./Images/Header_user.png";
import Header_heart from "./Images/Header_heart.png";
import Header_cart from "./Images/Header_cart.png";
import Header_searchlens from "./Images/Header_searchlens.png";
import goldpic from "./Images/header_menubar_gold.png";
import goldpic1 from "./Images/header_menubar_gold_popupright.png";
import header_menumob from "./Images/header_menumob.png";
import logo from "./Images/logo1.png";
import { FiUser } from "react-icons/fi";
import logom from "./Images/logom.png";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs";
import {BsWhatsapp} from "react-icons/bs";
import Mobmenubar from "./Mobmenubar";
import LoadingBar from 'react-top-loading-bar';
import { useDispatch, useSelector } from "react-redux";

export default function Header({
  handleCartClick,
  handleLoginClick,
  handleHomeClick,
  handleMenuClick,
  // handleShopClick,
  handleWishListClick,
  isNeeded,
  isClicked,
  isPop,
  array,
  handleContactClick,
  handleShopClick1
}) {

  const ref = useRef(null)
  const cart = useSelector(state => state.cart)
  const navigate = useNavigate();
  const [itm, setItm] = useState({});
  const [cid, setCid] = useState("");
  const baseurl = config.url;
  // const [isPop, setIsPop] = useState(false);

  const usr = useSelector(state => state.user)
  const dispatch = useDispatch();
  const loginClick = e => {
    // setIsPop(current => !current);
    // alert('here')
    if (usr.length > 0) {
      dispatch({ type: "setUser", payload: [] })
      // navigate("/");
    } else {
      navigate("/Loginpage");
    }
  }

  const handleloginClick = e => {
    // setIsPop(current => !current);
    // alert('here')
    if (usr.length > 0) {
      dispatch({ type: "setUser", payload: [] })
      alert("Successfully Logout!");
    } else {
      navigate("/Loginpage");
    }
  }

  const logoutClick = () => {
    dispatch({ type: "setUser", payload: [] })
    localStorage.removeItem('loginid');
    navigate("/")
  };
  // const handleLoginClick = () => {
  //   navigate("/Loginpage");
  // };
  // const handleHomeClick = () => {
  //   navigate("/");
  // };
  // const handleShopClick = () => {
  //   navigate("/Shoppage");
  // };
  const handleWishListMobviewClick = () => {
    navigate("/wishlistpage");
    // console.log("he")
  }
  const size = localStorage.getItem("cartlength");
  // console.log("header length==>", size);

  const handleShopClick = () => {
    // setProgress(progress + 10);
    localStorage.removeItem("menuid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("styleid");
    localStorage.removeItem("typeid");
    localStorage.removeItem("metalid");
    localStorage.removeItem("catid");
    localStorage.removeItem("subcatid");
    localStorage.removeItem("stid");
    localStorage.removeItem("tyid");
    localStorage.removeItem("metid");
    localStorage.removeItem("menuid1");
    localStorage.removeItem("subcatid1");
    localStorage.removeItem("styleid1");
    localStorage.removeItem("typeid1");
    localStorage.removeItem("metalid1");
    navigate("/Shoppage");
    window.scrollTo(0,0);
  };

  const [isSelected, setIsSelected] = useState(false);
  const handleGoldClick = (e) => {
    setIsSelected(current => !current);
    console.log("a==>" + JSON.stringify(array.goldrate));
  }

  // window.onscroll = function () { myFunction() };

  // var header = document.getElementById("myHeader");
  // var sticky = header.offsetTop;

  // function myFunction() {
  //   if (window.pageYOffset > sticky) {
  //     header.classList.add("sticky");
  //   } else {
  //     header.classList.remove("sticky");
  //   }
  // }
  return (
    
    <div className="Header">
      <LoadingBar color='#f11946' ref={ref} />
      <div className="Header_r1">
        <div className="Header_r1_left1 ">

          {/* <div className="Header_r1_left_mailid">
            <div className="Header_r1_left_mailid_img">
              <img src={Header_envelope} />
            </div>

            <div className="Header_r1_left_mailid_mail">
              <label>maharajagoldanddiamonds@gmail.com</label>
            </div>
          </div>
          <div className="Header_r1_left_contact">
            <div className="Header_r1_left_contact_img">
              <img src={Header_phone} />
            </div>
            <div className="Header_r1_left_contact_number">
              <label> (91)808695916</label>
            </div>
          </div> */}
        </div>

        <div className="Header_r1_right1">
          <div className="Header_r1_left_contact">
            <div className="Header_r1_left_contact_img">
              {/* <img src={Header_phone} /> */}
              <BsWhatsapp/>
            </div>
            <div className="Header_r1_left_contact_number">
              <label> (91)808695916</label>
            </div>
          </div>
          {/* <div className="Header_r1_right_1">
            <select>
              <option> English</option>
              <option> Hindi</option>
              <option> Tamil</option>
            </select>
          </div> */}

          {/* <div className="Header_r1_right_2">
            <select>
              <option> INR</option>
              <option> AUD</option>
              <option> USD</option>
            </select>
          </div> */}


        </div>
      </div>
      <div className="Header_r2">
        <div className="Header_r2_col1">
          <img
            src={header_menumob}
            className="header_mobview1"
            isChecked={isClicked}
            onClick={(e) => handleMenuClick(e)}
          />
          {/* <label> Logo</label> */}
          <div className="Header_r2_col1_logo">
            <img src={logo} style={{ width: "250px" }} />
          </div>
          <div className="header_mobview_outer">
            <div className="header_mobview_login">
              {/* <img
                src={Header_user}
                className="header_mobview"
                onClick={(e) => handleloginClick()}
              /> */}
              {/* <Loginpopup /> */}
            </div>
            {/* <img src={Header_heart}
              className="header_mobview"
              onClick={(e) => handleWishListClick()}
            /> */}
            <div className="header_mobview">
              <FiUser style={{ color: "white", fontSize: "22" }} onClick={(e) => loginClick()} />
            </div>
            <div className="header_mobview">
              <AiOutlineHeart style={{ color: "white", fontSize: "22", fontWeight: "950" }} onClick={(e) => handleWishListClick()} />
            </div>
            <div className="header_mobview">
              {/* <img
                src={Header_cart}
                className="header_mobview"
                onClick={(e) => handleCartClick()}
              /> */}
              <FiShoppingCart
                style={{ color: "white", fontSize: "20", fontWeight: "850" }}
                onClick={(e) => handleCartClick()}
              />
              <span className="Header_r1_right_5_count_mobview">{cart.length}</span>
            </div></div>
        </div>
        <div className="Header_r2_col2_outer">
          <div className="Header_r2_col2_outer_inner">
            <ul className="Header_r2_col2">
              <li onClick={(e) => handleHomeClick()}>Home</li>
              {/* |<li>Pages</li>|
            <li>Products</li>|<li>Blog</li> */}
              |
              <li onClick={(e) =>{ handleShopClick();handleShopClick1()}}>Shop</li>|<li onClick={(e)=>handleContactClick(e)}>Contact</li>
            </ul>
            {isPop ?
              <ul className="Header_r2_col2_mobview">
                <li onClick={(e) => handleHomeClick()}>Home</li>|
                {/* <li>Pages</li>|
            <li>Products</li>|<li>Blog</li>| */}
                <li onClick={(e) => {handleShopClick();handleShopClick1()}}>Shop</li>|
                <li >
                  <label onClick={(e) => handleGoldClick()}>Gold Rate</label>
                  {isSelected ?
                  // <div className="Header_r2_col2_mobview_menu">
                  // <Mobmenubar/>
                  // </div>
                    <div>
                      {array.goldrate.map((itm, indx) => {
                        return (
                          <div className="popup_title_goldrate">
                            <label>Today's Gold Rate</label>
                            <div className="popup_title_goldrate_row1">
                              <label>22 KT(916)</label>
                              <span>-</span>
                              <label>Rs. {itm.goldrate}/g</label>
                            </div>
                            <div className="popup_title_goldrate_row1">
                              <label>18 KT(750)</label>
                              <span>-</span>
                              <label>Rs. {itm.lowgoldrate}/g</label>
                            </div>
                            <div className="popup_title_goldrate_row1">
                              <label>Updated on</label>
                              <span>:</span>
                              <label>{itm.date}</label>
                            </div>
                            <label>{itm.time}</label>

                          </div>
                        );
                      })}
                    </div> 
                    : ""}
                </li>|<li onClick={(e)=>handleContactClick(e)}>Contact</li>
              </ul> : null}
            {/* <div className="Header_r2_col2_extra"></div> */}
          </div>
          <div className="Header_r1_right">
            <div className="Header_r1_right_4" onClick={(e) => handleWishListClick()}>
              <div className="Header_r1_right_4_img" >
                <AiOutlineHeart style={{ color: "white", fontSize: "20" }} />
              </div>
              <div className="Header_r1_right_4_wishlist">
                <label > Wishlist</label>
              </div>
            </div>
            <div className="Header_r1_right_5">
              <span className="Header_r1_right_5_span">
                <FiShoppingCart
                  style={{ color: "white", fontSize: "18" }}
                  onClick={(e) => handleCartClick()}
                />
              </span>
              <span className="Header_r1_right_5_count">{cart.length}</span>
              <label>Cart</label>
            </div>

            <div className="Header_r1_right_3">
              <div className="Header_r1_right_3_img">
                <BsFillPersonFill style={{ color: "white", fontSize: "18" }} onClick={(e) => loginClick()} />
              </div>
              <div className="Header_r1_right_login">
                <label onClick={(e) => loginClick()}
                  className={usr.length > 0 ? "Header_r1_right_login_button_bold" : "Header_r1_right_login_button"}
                > {usr.length > 0 ? usr[0].firstname : "Login"} {usr.length > 0 ? "," : ""}
                </label>
                {/* <label></label> */}
                {usr.length > 0 ?
                  <label onClick={(e) => logoutClick()}
                    className={usr.length > 0 ? "Header_r1_right_login_button_bold" : "Header_r1_right_login_button"}
                  > Logout</label>
                  : ""}
                {/* {isPop ?
              <div>
              <Loginpopup /> 
              </div>
              : <></>} */}
              </div>


            </div>
          </div>
          {/* <div className="Header_r1_left ">
            <div className="Header_r1_left_mailid">
              <div className="Header_r1_left_mailid_img">
                <img src={Header_envelope} />
              </div>

              <div className="Header_r1_left_mailid_mail">
                <label>maharajagoldanddiamonds@gmail.com</label>
              </div>
            </div>
            <div className="Header_r1_left_contact">
              <div className="Header_r1_left_contact_img">
                <img src={Header_phone} />
              </div>
              <div className="Header_r1_left_contact_number">
                <label> (91)808695916</label>
              </div>
            </div>
          </div> */}
          {/* <div className="Header_r2_col3"> */}
          {/* <input type="text" /> */}
          {/* <div className="Header_r2_col3 input"> */}
          {/* <img src={Header_searchlens} /> */}
          {/* </div> */}
          {/* </div>  */}
        </div>
      </div>
      {/* {isNeeded?<Menubar />:<></>} */}
    </div>
  );
}

function Loginpopup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usr = useSelector(state => state.user)
  const handleOrderHistoryClick = (e) => {
    navigate("/Orderlistpage");
  }
  const handleLogoutClick = (e) => {
    dispatch({ type: "setUser", payload: [] })
  }

  return (
    <>
      <div className={usr.length > 0 ? "loginpopup" : "loginpopup_hide"}>
        <div className="loginpopup_menu">
          <label onClick={(e) => handleOrderHistoryClick(e)}>Order History</label>
        </div>
        <div className="loginpopup_menu">
          <label onClick={(e) => handleLogoutClick(e)}>Logout</label>
        </div>

      </div>
    </>
  )
}

function Popup({ array }) {
  return (
    <>
      <div>
        {array.goldrate.map((itm, indx) => {
          return (
            <div className="menubar_title_goldrate">
              <label>Today's Gold Rate</label>
              <div className="menubar_title_goldrate_row1">
                <label>22 KT(916)</label>
                <span>-</span>
                <label>Rs. {itm.goldrate}/g</label>
              </div>
              <div className="menubar_title_goldrate_row1">
                <label>18 KT(750)</label>
                <span>-</span>
                <label>Rs. {itm.lowgoldrate}/g</label>
              </div>
              <div className="menubar_title_goldrate_row1">
                <label>Updated on</label>
                <span>:</span>
                <label>{itm.date}</label>
              </div>
              <label>{itm.time}</label>

            </div>
          );
        })}
      </div>
    </>
  )
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Orderdetailpage.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subbanner from "../Components/Subbanner";
import Orderdetails from "../Components/Orderdetails";
import Whatsapp from "../Components/Whatsapp";
import Rating from "../Components/Rating";
import config from "../config.json";
import Menubar from "../Components/Menubar";
import Loader from "../Components/Loader";

export default function Orderdetailpage() {
  const baseurl = config.url;
  const [array1, setArray1] = useState([]);
  const navigate = useNavigate();
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const handleLoginClick = () => {
    navigate("/Loginpage");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShopClick = () => {
    navigate("/Shoppage");
  };
  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }
  const handleCancelOrderClick = (e) => {
    var a = localStorage.getItem("hid");
    var b = localStorage.getItem("oid");
    const url = baseurl + "cancelorder";
    const req = { hdrid: a, ordid: b };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log(res.data);
        setArray1(res.data);
        alert("Order Cancelled");
        navigate("/Orderlistpage");
      })
      .catch((err) => {
        console.log(err);
      });
  };
const [show,setShow]=useState(false)
const [isshow,setIsShow]=useState(false);
const [isshow1,setIsShow1]=useState(false);
const [isshow2,setIsShow2]=useState(false);
  const [array, setArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [], "orderdetails": [] });
  const uid = localStorage.getItem("ordid");
  console.log("current ord id==>", uid);
  useEffect(() => {
    window.scrollTo(0,0);
    setShow(true);
    const url = baseurl + "getorderdetail";
    const req = { id: uid };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        console.log("status==>"+res.data.orderdetails[0].status);
        setArray(res.data);
        setShow(false);
        localStorage.setItem("hid", res.data.orderdetails[0].hdrid);
        localStorage.setItem("oid", res.data.orderdetails[0].ordid);
        if(res.data.orderdetails[0].status=="Processing"){
          setIsShow(true);
        }
        if(res.data.orderdetails[0].status=="Conformed"){
          setIsShow(true);
          setIsShow1(true);
        }
        if(res.data.orderdetails[0].status=="Delivered"){
          setIsShow(true);
          setIsShow1(true);
          setIsShow2(true);
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    setIsPop(current => !current);
  };

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);
}

  return (
    <>
      <div className="orderdetailpage">
        <div className="orderdetailpage_header">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          handleShopClick={handleShopClick}
          handleWishListClick={handleWishListClick}
          handleMenuClick={handleMenuClick}
          handleContactClick={handleContactClick}
          isNeeded={false}
          isPop={isPop}
          array={array}
        />
        </div>
        {/* {isPop ?
          <div className="orderdetailspage_menubar_mobview">
            <Menubar
              array={array}
            />
          </div>
          : null} */}
        <Loader show={show} setShow={setShow} />
        <Subbanner title={"My Orders"} />
        <div className="orderdetailpage_col">
          <div className="orderdetailpage_col_col1">
            <Orderdetails
              handleCancelOrderClick={handleCancelOrderClick}
              array={array}
              isshow={isshow}
              isshow1={isshow1}
              isshow2={isshow2}
            />
          </div>
          <div className="orderdetailpage_col_col2">
            <Rating />
          </div>
        </div>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
        // handleOrderDetailPageClick={handleOrderDetailPageClick}
        />
        <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import chair1 from "./Images/topcategories_chair1.png";
import chair2 from "./Images/topcategories_chair2.png";
import chair3 from "./Images/topcategories_chair3.png";
import chair4 from "./Images/topcategories_chair4.png";
import image1 from "./Images/topcategories_image1.png";
import slideimage from "./Images/topcategories_slideimage.png";
import "./Topcategories.css";
import config from "../config.json";
import Loader from "./Loader";

export default function Topcategories({
  handleShoppageClick,
  handleViewDetailsClick,
  cards1,
  setShow,
  show,
}) {
  const baseurl = config.url;
  // const [array, setArray] = useState([]);
  // const [cards1, setCards1] = useState({});
  const [pageNumber, setPageNumber] = useState(0);

  // useEffect(() => {
  //   const url = baseurl + "getcategories";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       console.log(res.data);
  //       setArray(res.data);
  //       setCards1(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  let itemPerpage = 4;
  let totalPage = Object.keys(cards1).length / itemPerpage;
  const switchPage = (index) => {
    setPageNumber(index);
  };
  let cardDisplay = Object.keys(cards1)
    .slice(pageNumber * itemPerpage, pageNumber * itemPerpage + itemPerpage)
    .map((item) => {
      return (
        <div keys={item}>
          <Topcategories_list
            image={cards1[item]["image"]}
            category={cards1[item]["prdname"]}
            code={cards1[item]["prdcode"]}
            price={cards1[item]["price"]}
            items={cards1[item]}
            handleShoppageClick={handleShoppageClick}
            handleViewDetailsClick={handleViewDetailsClick}
          />
        </div>
      );
    });

  let page = [];
  for (let i = 0; i < totalPage; i++) {
    page.push(i);
  }

  let pagination = page.map((item) => {
    return (
      <div
        className="Topcategories_footer_slider"
        style={
          pageNumber === item
            ? { backgroundColor: "#f61b7a", width: "25px" }
            : null
        }
        onClick={() => switchPage(item)}
      ></div>
    );
  });

  return (
    <>
      <div className="topcategories_loader">
        <Loader show={show} setShow={setShow} />
      </div>
      <div className="Topcategories">
        <div className="Topcategories_header">
          {/* <label>Top Categories</label> */}
          <label>Best Selling Products</label>
        </div>
        <div className="Topcategories_cont">{cardDisplay}</div>
        <div className="Topcategories_footer">{pagination}</div>
      </div>
    </>
  );
}

function Topcategories_list({
  image,
  handleShoppageClick,
  handleViewDetailsClick,
  code,
  category,
  price,
  items
}) {
  return (
    <>
      <div
        className="Topcategories_list"
        onClick={(e) => handleShoppageClick(e,{items})}
      ><div className="Topcategories_list_img_outer">
        <div className="Topcategories_list_img">
          <img src={image}></img>
          <div>
          </div>
            {/* <button
              className="Topcategories_list_img_button"
              onClick={(e) => handleShoppageClick()}
            >
              View Shop
            </button> */}
          </div>
        </div>
        <div className="Topcategories_list_label">
          <label className="Topcategories_list_label_l1">{category}</label>
          <label className="Topcategories_list_label_l2">SKU : {code}</label>
          <label className="Topcategories_list_label_l3">Rs. {price}</label>
        </div>
      </div>
    </>
  );
}

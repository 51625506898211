import React, { useState } from "react";
import "./Footer.css";
import fb from "./Images/footer_facebook.png";
import cam from "./Images/footer_camscan.png";
import twit from "./Images/footer_twitter.png";
import {MdOutlineEmail} from "react-icons/md"
import { SiFacebook } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import {AiFillInstagram} from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import {BsYoutube} from "react-icons/bs";
import logom from "./Images/logom.png";
import Logof from "./Images/logof1.png";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {BsWhatsapp} from "react-icons/bs";
import config from "../config.json";
import axios from "axios";

export default function Footer({  contact1,handleOrderDetailPageClick, handleProfilePageClick, handleOrderListClick }) {
  const usr = useSelector(state => state.user);
  const baseurl=config.url;
  const navigate = useNavigate();
  const [email,setEmail]=useState("");
  const [array,setArray]=useState([]);
  const handleHomepageClick = (e) => {
    navigate("/");
  }

  const handleShoppageClick = (e) => {
    navigate("/Shoppage");
  }
  const handleSignupClick=()=>{
    const url=baseurl+"addsubscribe";
    const req={
      email:email
    }
    const header={}
    axios 
    .post(url,req,header)
    .then((res)=>{
      setArray(res.data)
      setEmail("");
    })
    .catch((err)=>{})

  }

  return (
    <>
      <div className="footer">
        <div className="footer_row1">
          <div className="fooer_row1_row1">
            <div className="footer_row1_col1">
              <h1 className="footer_row1_col1_h1"><img src={Logof} style={{ width: "200px" }}/></h1>
              <div className="footer_row1_col1_input">
                <input placeholder="Enter Email Address"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}></input>
                <button onClick={e => handleSignupClick()}>Subscribe</button>
              </div>
              {/* <label>Contact Info</label>
              <label>
                17 Princess Road, London, Greater London NW1 8JR, UK
              </label> */}
            </div>
            <div className="footer_row1_col2">
              <h3>Branches</h3>
              <ul>
                <li>
              <label className="footer_row1_col2_col1">Eramaloor </label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086662916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Edapally</label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086160916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Tripunitura</label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086339916 </label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Thrissur</label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086729916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Palluruthy</label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086075916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Aroor</label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086229916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Thuravoor </label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086669916</label>
              </li>
              <li>
              <label className="footer_row1_col2_col1">Kalady </label>
              <span className="footer_row1_col2_col2">:</span>
              <label className="footer_row1_col2_col3"> 8086094916</label>
              </li>
              </ul>
            </div>
            <div className="footer_row1_col2_page">
              <h3>Pages</h3>
              <label onClick={e => handleHomepageClick()}>Home</label>
              {/* <label>Pages</label> */}
              {/* <label>Products</label> */}
              {/* <label>Blog</label> */}
              <label onClick={e => handleShoppageClick()}>Shop</label>
              {/* <label>Contact</label> */}
              {usr.length > 0 ?
              <label onClick={e => handleOrderListClick()}>Orders History</label>
              :""}
              {usr.length > 0 ?
                <label onClick={e => handleProfilePageClick()}>My Account</label>
                : ""}
            </div>
            <div className="footer_row1_col2">
              <h3 ref={(ip)=>contact1=ip} id="contactus">Contact Us</h3>
              <label ><span><MdOutlineEmail/></span>maharajagoldanddiamonds@gmail.com</label>
              <label ><span><BsWhatsapp/></span>(91)8086449916</label>
              {/* <label>Discount</label>
              <label>Returns</label>
              <label onClick={e => handleOrderListClick()}>Orders History</label>
              <label onClick={e => handleOrderListClick()}>Order Tracking</label> */}
            </div>
          </div>
        </div>
        <div className="footer_row2">
          <div className="footer_row2_col1">
            <label> ©Maharaja Gold And Diamonds - All Rights Reserved</label>
          </div>
          <div className="footer_row2_col2">
            <div >
              <a href="https://www.facebook.com/maharajagoldndiamonds?mibextid=ZbWKwL">
              <SiFacebook style={{ color: "#FFFFFF" }} />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/maharajagoldanddiamonds?igshid=YmMyMTA2M2Y=">
              <AiFillInstagram style={{ color: "#FFFFFF",fontSize:"20" }} />
              </a>
            </div>
            {/* <div>
            <a href="">
              <AiFillTwitterCircle style={{ color: "#FFFFFF",fontSize:"20" }} />
              </a>
            </div> */}
            <div>
            <a href="https://youtube.com/@MaharajaGoldandDiamonds">
              <BsYoutube style={{color:"#FFFFFF" ,fontSize:"20"}}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

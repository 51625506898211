import "./Loader.css";
import logo from "./Images/logom.png"

const Loader = ({ show }) => {
  return (
  <>
     {/* <div className="loder_content"> */}
      <div className={show ? "loader" : "hidden"}>
        {/* <div className="loader_img"> */}
        <img src={logo}/>
        {/* </div> */}
      {/* </div> */}
     </div>
    </>
  );
};
export default Loader;



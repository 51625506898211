import Header from "../Components/Header";
import Mainbanner from "../Components/Mainbanner";
import Featuredlist from "../Components/Featuredlist";
import LatestProducts from "../Components/LatestProducts";
import Offerlist from "../Components/Offerlist";
import InBetweenBanner1 from "../Components/InBetweenBanner1";
import TrendingProducts from "../Components/TrendingProducts";
import Discountitem from "../Components/Discountitem";
import Topcategories from "../Components/Topcategories";
import InBetweenBanner2 from "../Components/InBetweenBanner2";
import Latestblog from "../Components/Latestblog";
import Footer from "../Components/Footer";
import "./Homepage.css";
import BrandList from "../Components/BrandList";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../config.json";
import Menubar from "../Components/Menubar";
import Loader from "../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../Components/Featuredlist";
import Slideshow from "../Components/Slideshow";
import Whatsapp from "../Components/Whatsapp";

export default function Homepage() {
  const baseurl = config.url;
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const [cart, setCart] = useState("")
  const handleCartClick = () => {
    navigate("/CartPage");
  };
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    // alert('here')
    if (user.length > 0) {
      // dispatch({ type: "setUser", payload: [] })

    } else {
      navigate("/Loginpage");
    }
  };

  const handleSignupClick = () => {
    navigate("/Loginpage");
  };
  const handleProfilePageClick = () => {
    navigate("/Profilepage");
  };
  const handleOrderListClick = () => {
    navigate("/Orderlistpage");
  };
  const handleOrderDetailPageClick = () => {
    navigate("/Orderdetailpage");
  };
  const handleWishListClick = () => {
    navigate("/wishlistpage");
  }
  const handleViewDetailClick = (e, itm) => {
    e.preventDefault();
    localStorage.setItem("prdid", JSON.stringify(itm.items.id));
    localStorage.setItem("prdcategory", itm.items.prdcategory);
    localStorage.setItem("prdstyle", itm.items.prdstyle);
    localStorage.setItem("prdmetal", itm.items.prdmetal);
    localStorage.setItem("prdsubcat", itm.items.prdsubcategory);
    localStorage.setItem("prdtype", itm.items.prdtype);

    // console.log("Currentid==>",JSON.stringify(itm.items));
    navigate("/Singleproductpage");
  };
  const handleShoppageClick = () => {
    navigate("/Shoppage");
  };
  const handleViewDetailsClick = (e, itm) => {
    e.preventDefault();
    localStorage.setItem("prdid", itm.id);
    localStorage.setItem("prdcategory", itm.category);
    localStorage.setItem("prdstyle", itm.prdstyle);
    localStorage.setItem("prdmetal", itm.prdmetal);
    localStorage.setItem("prdsubcat", itm.prdsubcategory);
    localStorage.setItem("prdtype", itm.prdtype);

    // localStorage.getItem
    // console.log("Currentid==>", itm.id);
    navigate("/Singleproductpage");
  };
  // const handleTrendingPrdClick=(e,itm)=>{
  //   e.preventDefault();
  //   localStorage.setItem("prdid",itm.id);
  //   navigate("/Singleproductpage");
  // }
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const [cards, setCards] = useState({});
  const [cards1, setCards1] = useState({});
  // const [array, setArray] = useState({ "trending": [], "latestprd": [], "featured": [], "topcategory": [], "blog": [], "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [], "slider": [] });
  // useEffect(() => {
  //   setShow(true)
  //   const url = baseurl + "fetchhomepage";
  //   const req = {};
  //   const header = {};
  //   axios
  //     .post(url, req, header)
  //     .then((res) => {
  //       setShow(false)
  //       // if (res.data > 0) {
  //       setArray(res.data);
  //       // setCards(res.data.featured);
  //       // setCards1(res.data.topcategory);
  //       console.log("latest product==>", res.data);
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  // }, []);
  const [array, setArray] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    setShow1(true)
    const url = baseurl + "fetchfeaturedproducts";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow1(false);
        console.log(res.data);
        setArray(res.data);
        setCards(res.data);
        // localStorage.setItem("prdlength", res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [latestArray, setLatestArray] = useState([]);

  useEffect(() => {
    setShow2(true)
    const url = baseurl + "fetchlatestproducts";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        // if (res.data > 0) {
        setShow2(false)
        setLatestArray(res.data);
        console.log("latest product==>", res.data);
        // }
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const [array1, setArray1] = useState([])

  useEffect(() => {
    setShow4(true)
    const url = baseurl + "getcategories";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow4(false)
        console.log(res.data);
        setArray1(res.data);
        setCards1(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [trendingprd, setTrendingprd] = useState([]);

  useEffect(() => {
    setShow3(true)
    const url = baseurl + "fetchtrendingproduct";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow3(false)
        // console.log(res.data);
        setTrendingprd(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [slidearray, setSlideArr] = useState([]);

  useEffect(() => {
    // setShow(true)
    const url = baseurl + "fetchslider";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        // setShow(false)
        console.log("slide img==>" + JSON.stringify(res.data));
        const a = JSON.stringify(res.data)
        setSlideArr(res.data);
        //  setSlider1(res.data[0].slideimage);
        //  setSlider2(res.data[1].slideimage);
        //  setSlider3(res.data[2].slideimage);
        //  setSlider4(res.data[3].slideimage);
        //  setSlider5(res.data[4].slideimage);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [blogarray, setBlogArr] = useState([]);

  useEffect(() => {
    setShow(true)
    const url = baseurl + "getblogs";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        console.log("blog==>" + res.data);
        setBlogArr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [catArray, setCatArray] = useState({ "category": [], "subcat": [], "style": [], "weartype": [], "metal": [], "goldrate": [] });

  useEffect(() => {
    const url = baseurl + "fetchmenulist";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        // if(res.data>0){
        setCatArray(res.data);
        // }
        console.log("menu array==>", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [style, setStyle] = useState("headermenu");
  const [isVisible, setIsVisible] = useState(false);
  const [isPop, setIsPop] = useState(false);
  const handleMenuClick = () => {
    // setStyle("Header_r2_col2_outer");
    setIsVisible((current) => !current);
    setIsPop(current => !current);
  };

  const contact1 = useRef(null);

  const handleContactClick = (e) => {
    // contact1.current.focus();
    window.scrollTo(0, 10000);

    document.getElementById("homepage_footer").focus();

  }
  const handleHomeClick = () => {
    window.scrollTo(0, 0);
    window.location.reload()
    // show(true)
  }

  return (
    <>
      <div className="Homepage_header">
        <Header
          handleCartClick={handleCartClick}
          handleLoginClick={handleLoginClick}
          handleHomeClick={handleHomeClick}
          // handleShopClick={handleShopClick}
          handleMenuClick={handleMenuClick}
          handleWishListClick={handleWishListClick}
          // isNeeded={true}
          array={catArray}
          isPop={isPop}
          handleContactClick={handleContactClick}
        />
        <div className="Homepage_menubar">
          <Menubar array={catArray} />
        </div>
      </div>
      
      {/* {isPop ? */}
      {/* <div className="Homepage_menubar_mobview">
          <Menubar array={catArray} />
        </div> */}
      {/* : null} */}

      {slidearray.length > 0 ?

        // <Mainbanner
        //   handleShoppageClick={handleShoppageClick}
        //   array={array}
        // /> 
        <div className="homepage_slidercomponent" >
          <Slideshow
            array={slidearray}
          // setShow={setShow}
          // show={show}
          />
        </div>
        : <></>}

      <div className="componentfeaturedlist">
        <Featuredlist handleViewDetailClick={handleViewDetailClick}
          cards={cards}
          setShow={setShow1}
          show={show1}
        />
      </div>
      {/* <div className="homepage"> */}
      <div className="componentlatestproducts">
        <LatestProducts handleViewDetailsClick={handleViewDetailsClick}
          latestArray={latestArray}
          setShow={setShow2}
          show={show2}
        />
      </div>
      {/* </div> */}
      <div className="componentofferlist">
        <Offerlist />
      </div>
      <div className="componentinbetweenbanner1">
        <InBetweenBanner1 />
      </div>
      <div className="componenttrendingproducts">
        <TrendingProducts
          handleViewDetailsClick={handleViewDetailsClick}
          array={trendingprd}
          setShow={setShow3}
          show={show3}
        // handleTrendingPrdClick={handleTrendingPrdClick}
        />
        <div className="hompage_outer">
          <Discountitem handleShoppageClick={handleShoppageClick} />
        </div>
        <div className="componentinbetweenbanner2">
          <InBetweenBanner2 handleShoppageClick={handleShoppageClick} />
        </div>
        <Topcategories
          handleShoppageClick={handleViewDetailClick}
          handleViewDetailsClick={handleViewDetailsClick}
          cards1={cards1}
          setShow={setShow4}
          show={show4}
        />
      </div>

      <BrandList />
      <div className="homepage_latestblog">
        <Latestblog
          array={blogarray}
          setShow={setShow}
          show={show}
        />
      </div>
      <div className="homepage_footer" id="homepage_footer" ref={contact1}>
        <Footer
          handleSignupClick={handleSignupClick}
          handleProfilePageClick={handleProfilePageClick}
          handleOrderListClick={handleOrderListClick}
          handleOrderDetailPageClick={handleOrderDetailPageClick}
        // contact1={contact1}
        />
      </div>
      <div className="homepage_whatsapp">
        <Whatsapp />
      </div>
    </>
  );
}

import { useState } from "react";
import { useEffect } from "react";
import bag1 from "./Images/checkout_bag1.png";
import bag2 from "./Images/checkout_bag2.png";
import bag3 from "./Images/checkout_bag3.png";
import bag4 from "./Images/checkout_bag4.png";
import bag5 from "./Images/checkout_bag5.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Wishlist.css";

export default function Wishlist() {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const array = useSelector(state => state.wishlist);
  // const [array, setArray] = useState([]);

  const handleAddtoCartClick = (e, itm) => {
    // var cartitms =
    //   JSON.parse(localStorage.getItem("cartitms")) || JSON.parse("[]");
    // var cartitm = { array };
    // console.log("cart itms new==>",cartitms.length);
    // cartitms[cartitms.length] = cartitm.array[0];
    // localStorage.setItem("cartitms", JSON.stringify(cartitms));
    // console.log("cart itms new==>",cartitms);
    // localStorage.setItem("cartlength", cartitms.length);
    let temp = [...cart];
    let added = false;
    for (const iterator of temp) {
      if (iterator.id == itm.id) {
        iterator.qty = iterator.qty + 1;
        added = true;
      }
      console.log(iterator)
    }
    if (!added) {
      itm.qty = 1;
      temp = [...temp, itm]
    }
    console.log(temp)
    dispatch({ type: "setCart", payload: temp });

    // const temp1 = array.filter(item => item.id !== itm.id);
    // dispatch({ type: "setWishlist", payload: temp1 });

  };
  const handleClearSingleWishlist=(e,itm)=>{
    const temp1 = array.filter(item => item.id !== itm.id);
    dispatch({ type: "setWishlist", payload: temp1 });

  }


  const handleClearWishlist = () => {
    dispatch({ type: "setWishlist", payload: [] })
  }

  const handleCartHomeClick = () => {
    navigate("/")
  }

  return (
    <>
      <div className="Wishlist">
        <div className="wishlist_right">
          <div className="Wishlist_right_header">
            <div className="Wishlist_right_header_h1">
              <label>Product</label>
            </div>
            <div className="Wishlist_right_header_h2">
              <label>Price</label>
            </div>
          </div>
          <div className="Wishlist_right_cont">
            {array.map((itm, indx) => {
              return (
                <SingleWishlist
                  image={itm.image}
                  label={itm.prdname}
                  color={itm.color}
                  price={itm.price}
                  itm={itm}
                  handleClearSingleWishlist={handleClearSingleWishlist}
                  handleAddtoCartClick={handleAddtoCartClick}
                />
              );
            })}
          </div>
          {array.length===0?"":
          <div className="wishlist_button_btn2">
            <button onClick={(e) => handleClearWishlist(e)}>Clear wishlist</button>
          </div>
          }
        </div>
        {array.length === 0 ?
              <div className="wishlist_right_cont_navigation">
                <label onClick={(e) => handleCartHomeClick(e)}>Back to Home</label>
              </div>
              :""}
      </div>
    </>
  );
}
function SingleWishlist({ image, label, handleAddtoCartClick, color, price, itm,handleClearSingleWishlist }) {
  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }


  return (
    <>
      <div className="singlewishlist">
        <div className="singlewishlist_product">
          <img src={image}></img>
          <div className="singlewishlist_product_label">
            <label className="singlewishlist_product_label_l1">{label}</label>
            <label className="singlewishlist_product_label_l2">
              Color:{color}
            </label>
            <label className="singlewishlist_product_label_l3">Size:XL</label>
          </div>
        </div>
        <div className="singlewishlist_price">
          <label>{price}</label>
        </div>
        <div className="singlewishlist_button_btn2">
          <button onClick={(e) => { handleAddtoCartClick(e, itm); notify(); }}>Add to Cart</button>
          <div id="snackbar"> Added to Cart</div>
          <div className="wishlist_button_btn3">
            <button onClick={(e) => handleClearSingleWishlist(e,itm)}>Clear</button>
          </div>
        </div>
      </div>
    </>
  );
}

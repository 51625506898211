import React from "react"
import "./Loadmore.css";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import logo from "./Images/logom.png"
// import {Button,toaster} from ""
// import Loader from "./Loader";

export default function Loadmore({ handleLoadmoreClick, show, setShow, label }) {
  return (
    <>
      <div className="loadmore">
        {/* <div className="Loadmore_button_loader">
      </div> */}
        <div className="Loadmore_button">
          <button onClick={(e) => handleLoadmoreClick(e)}>{label}
            <Singleloader show={show} setShow={setShow} />
          </button>
          <ToastContainer />
        </div>
      </div>
    </>
  )
}


function Singleloader({ show }) {
  return (
    <>
      {/* <div className="loder_content"> */}
      <div className={show ? "Loadmore_loader" : "loadmore_hidden"}>
        <img src={logo}/>
      </div>
      {/* </div> */}
    </>
  );
};



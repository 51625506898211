import image from "./Images/checkout_image1.png";
import "./Checkout.css";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";

export default function Checkout({
  handleShippingdetailsClick,
  handleClearCart,
}) {
  const cart = useSelector((state) => state.cart);
  const [maintotal, setMainTotal] = useState(0);
  const baseurl = config.url;
  const navigate = useNavigate();
  const [cartarray, setCartArray] = useState([]);
  const [array, setArray] = useState([]);
  const [amountarray, setAmountArray] = useState([]);
  const [amount, setAmount] = useState("");
  const [dcharge, setDcharge] = useState("");
  // const [totalamount, setTotalAmount] = useState("");
  const [qtyobject, setQtyObject] = useState([]);
  const [totalArray, setTotalArray] = useState([]);
  const [counter, setCounter] = useState(1);
  const [tt, setTT] = useState(0);
  // const [addcart, setAddCart] = useState([
  //   JSON.parse(localStorage.getItem("cartitms")),
  // ]);
  var logid = localStorage.getItem("loginid");
  var tempqty = [];

  const handleClearCartItm = (e, itm) => { };
  const updatetotal = () => {
    let arr = [...cart];
    let temp = 0;
    for (const iterator of arr) {
      temp += iterator.price * iterator.qty;
    }
    // console.log("temp==>", temp);
    // console.log("a==>", a);
    console.log("Temp", temp);
    localStorage.setItem("totalamount", temp);
    setTT(temp);
  };

  useEffect(() => {
    // var a = JSON.parse(localStorage.getItem("cartitms"));
    // debugger;
    // const qty = localStorage.getItem("qty");
    // // for(const i = 0; i <a.length; i++){
    // //   if(a[i].id){}
    //   const c = a[0].price * qty;
    //   tempqty = [...tempqty, c];
    //   console.log("c==>",tempqty);
    // // }
    // setQtyObject(tempqty);
    // const qty = localStorage.getItem("qty");
    // console.log("cart price variable==>", qtyobject);
    setArray(cart);
    updatetotal();
    // const url1 = baseurl + "getcartamount";
    // const req1 = { uid: logid };
    // const header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     console.log(res.data);
    //     setAmountArray(res.data);
    //     setAmount(res.data[0].amount);
    //     setDcharge(res.data[0].dcharge);
    //     setTotalAmount(res.data[0].totalsum);
    //     localStorage.setItem("amount", res.data[0].totalsum);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  // localStorage.setItem("cartlength", array.length);
  // console.log("arraylength==>", array.length);
  // localStorage.setItem()
  // let buttonDecreaseClick = (e, itm) => {
  //   console.log("id==>", itm.itmid.id);
  //   const temp = counter - 1;
  //   setCounter(temp);
  //   localStorage.setItem("qty", counter);
  //   console.log("counter==>", counter);
  // };

  // const buttonIncreaseClick = (e, itm,) => {
  //   console.log("id==>", itm.itmid.id);
  //   if(array[0].id==itm.itmid.id){
  //     const temp = counter + 1;
  //   setCounter(temp);
  //   }
  //   const exist = array.find((x) => x.id === itm.itmid.id);
  //   if (exist) {
  //     setArray(
  //       array.map((x) =>
  //         x.id === itm.itmid.id ? { ...exist, qty: exist.qty + 1 } : x
  //       )
  //     );

  //   } else {
  //     setArray([...array, { ...itm, qty: 1 }]);
  //   }
  // };
  // localStorage.setItem("qty", counter);
  // console.log("counter==>", counter);
  // if (counter <= 0) {
  //   buttonDecreaseClick = () => setCounter(1);
  // }
  const handleCartHomeClick = () => {
    navigate("/")
  }
  const handleCartShopClick=()=>{
    navigate("/shoppage");
  }

  const dispatch = useDispatch();
  return (
    <>
      <div className="checkout">
        <div className="checkout_right">
          <div className="checkout_right_header">
            <div className="checkout_right_header_h1">
              <label className="checkout_right_header_h1_l">Product</label>
            </div>
            <div className="checkout_right_header_h2">
              <label className="checkout_right_header_h2_l">Price</label>
            </div>
            <div className="checkout_right_header_h3">
              <label className="checkout_right_header_h3_l">Quantity</label>
            </div>
            <div className="checkout_right_header_h4">
              <label className="checkout_right_header_h4_l">Total</label>
            </div>
          </div>
          <div className="checkout_right_cont">
            <div className="checkout_right_cont_empty">{cart.length === 0 && <div>Cart is Empty </div>}</div>
            {cart.map((itm, indx) => {
              return (
                <Checkout_cont
                  itm={itm}
                  indx={indx}
                  dispatch={dispatch}
                  updatetotal={updatetotal}
                  setTT={setTT}
                ></Checkout_cont>
              );
            })}
            <div className="checkout_right_cont_button">
              {cart.length === 0 ? "" :
                <div className="checkout_right_cont_button_btn2">
                  <button
                    onClick={(e) => {
                      handleClearCart(e);
                    }}
                  >
                    Clear Cart
                  </button>
                </div>}
            </div>
            {cart.length === 0 ?
              <div className="checkout_right_cont_navigation">
                <label onClick={(e) => handleCartHomeClick(e)}>Back to Home</label>
              </div>
              : 
              <div className="checkout_right_cont_navigation">
                <label onClick={(e) => handleCartShopClick(e)}>Continue Shopping</label>
              </div>
              }
          </div>
        </div>
        {cart.length === 0 ? "" :
          <div className="checkout_left">
            <div className="checkout_left_total">
              <div className="checkout_left_total_header">
                <label>Cart Total</label>
              </div>
              {/* {amountarray.map((itm, indx) => {
              return ( */}
              <div className="checkout_left_total_body">
                <div className="checkout_left_total_body_row1">
                  <div className="checkout_left_total_body_row1_l1">
                    <label>Amount </label>
                    <span className="checkout_left_total_body_row1_l1_s1">:</span>
                  </div>
                  <div className="checkout_left_total_body_row1_l2">
                    <label className="checkout_left_total_body_row1_l2_label">
                      amount
                      {/* {array.reduce((total,itm)=>total+(itm.price*itm.qty),0)} */}
                    </label>
                  </div>
                </div>
                <div className="checkout_left_total_body_row2">
                  <div className="checkout_left_total_body_row2_l1">
                    <label>Delivery Charges </label>
                    <span className="checkout_left_total_body_row1_l1_s2">:</span>
                  </div>
                  <div className="checkout_left_total_body_row2_l2">
                    <label className="checkout_left_total_body_row1_l2_label">
                      0
                    </label>
                    {/* <input
                    type="text"
                    placeholder="₹12"
                    value={dcharge}
                    onChange={(e) => setDcharge(e.target.value)}
                  ></input> */}
                  </div>
                </div>
                <div className="checkout_left_total_body_row3">
                  <div className="checkout_left_total_body_row3_l1">
                    <label>Total </label>
                    <label className="checkout_left_total_body_row1_l1_s3">:</label>
                  </div>
                  <div className="checkout_left_total_body_row3_l2">
                    {/* <input
                    type="text"
                    placeholder="₹135"
                    value={totalamount}
                    onChange={(e) => setTotalAmount(e.target.value)}
                  ></input> */}
                    <label className="checkout_left_total_body_row1_l2_label">
                      Rs. {tt}
                    </label>
                  </div>
                </div>

                <div className="checkout_left_total_body_button">
                  <button
                    type="button"
                    class="block"
                    onClick={(e) => {
                      handleShippingdetailsClick();
                    }}
                  >
                    Proceed To Checkout
                  </button>
                </div>
              </div>
              {/* );
            })}  */}
            </div>
          </div>
        }
      </div>
    </>
  );
}

function Checkout_cont({ itm, indx, dispatch, setTT }) {
  const cart = useSelector((state) => state.cart);
  // const baseurl = config.url;
  // let e = { qty };
  // const d = e.qty;
  // // console.log("b==>", a);
  // const [counter, setCounter] = useState(d);
  // const b = array.length;
  // localStorage.setItem("cartarraylength", b);
  // console.log("array length==>", b);
  // const c = price * counter;
  // // setTotalArray([...totalArray,c])
  // localStorage.setItem("prdid", itmid.id);
  // console.log("prdid==>" + itmid.id);
  // localStorage.setItem("prdqty", itmid.qty);
  // console.log("qty==>" + itmid.qty);
  // localStorage.setItem("prdprice", c);

  // const tempamount =tempamount+ price * qty;
  // setMainTotal(tempamount);

  const buttonDecreaseClick = (e) => {
    console.log("cartdec-=" + cart);
    // console.log("id==>", itm.itmid.id);
    // const temp1 = counter - 1;
    // setCounter(temp1);
    // const c = itm.itmid.price * temp1;
    // console.log("qty* total==>", c);

    // // console.log("a==>", a);

    // console.log("counter==>", counter);
    // const exist = array.find((x) => x.id === itm.itmid.id);
    // // if (exist.qty === 1) {
    // //   setArray(array.filter((x) => x.id !== itm.itmid.id));

    // // }
    // // else {
    // //   console.log("array==>", array);
    // //   setArray(
    // //     array.map((x) =>
    // //       x.id === itm.itmid.id ? { ...exist, qty: counter - 1 } : x
    // //     )
    // //   );
    // //   //   console.log("qrt includeded array==>", array);
    // // }

    let temp = [...cart];
    let k = 0;
    for (const iterator of temp) {
      if (iterator.id == itm.id) {
        if (iterator.qty - 1 > 0) iterator.qty = iterator.qty - 1;
      }
    }
    // let ab=0;
    //   for (const iterator of temp) {
    //     ab += iterator.qty * iterator.price;
    //   }
    //   setTT(ab)
    dispatch({ type: "setCart", payload: temp });
    setTT(k)
    window.location.reload();
  };

  const buttonIncreaseClick = (e) => {
    // // window.location.reload(true);
    // console.log("id==>", itm.itmid.id);
    // const tempt = counter + 1;
    // setCounter(tempt);
    // // const c = itm.itmid.price * temp;
    // // console.log("qty* total==>", c);
    // const exist = array.find((x) => x.id === itm.itmid.id);
    // if (exist.qty === 1) {
    //   setArray(
    //     array.map((x) =>
    //       x.id === itm.itmid.id ? { ...exist, qty: counter + 1 } : x
    //     )
    //   );
    //   console.log("qrt includeded array==>", array);
    //   // } else {
    //   //   setArray([...array, { ...itm, qty: 1 }]);
    // }

    let temp = [...cart];
    let k = 0;
    for (const iterator of temp) {
      k = iterator.qty * iterator.price;
      if (iterator.id == itm.id) {
        iterator.qty = iterator.qty + 1;

        // console.log("iterator.id-=" + JSON.stringify(iterator.id));
        // console.log("itm.id-=" + JSON.stringify(itm.id));
      }
    }
    // console.log(temp);
    // let ab=0;
    //   for (const iterator of temp) {
    //     ab += iterator.qty * iterator.price;
    //   }
    //   setTT(ab)
    dispatch({ type: "setCart", payload: temp });
    setTT(k)
    window.location.reload();
  };
  // localStorage.setItem("qty", counter);
  // console.log("counter==>", counter);
  // if (counter <= 0) {
  //   buttonDecreaseClick = () => setCounter(1);
  // }
  const handleSingleClearCart = (e, itm, indx) => {
    const temp = cart.filter(item => item.id !== itm.id);
    let k = 0;
    for (const iterator of temp) {
      k += iterator.qty * iterator.price;
    }
    console.log("clear cart==>" + k);
    setTT(k)
    dispatch({ type: "setCart", payload: temp });
  }

  return (
    <>
      <div className="singlecheckout">
        <div className="Checkout_cont">
          <div className="checkout_cont_product">
            <div className="checkout_cont_product_img">
              <img src={itm.image} width={50} height={50} />
            </div>
            <div className="checkout_cont_product_label">
              <label className="checkout_cont_product_label_l1">
                {itm.prdname}
              </label>
              <label className="checkout_cont_product_label_l2">
                Color: {itm.color}
              </label>
              <label className="checkout_cont_product_label_l3">
                Weight : {itm.weight}
              </label>
            </div>
          </div>
          <div className="checkout_cont_price">
            <label>Rs. {itm.price}</label>
          </div>
          <div className="checkout_cont_quantity">
            <button onClick={(e) => buttonDecreaseClick(e)}>-</button>
            <input
              type="text"
              value={itm.qty}
              id="inc"
            // onChange={(e) => setCounter(e.target.value)}
            ></input>
            <button onClick={(e) => buttonIncreaseClick(e)}>+</button>
          </div>
          <div className="checkout_cont_total">
            <div className="checkout_cont_total_inner">
              <label>Rs. {itm.qty * itm.price}</label>
              <div className="checkout_right_cont_button2">
                <div className="checkout_right_cont_button_btn3">
                  <button
                    onClick={(e) => handleSingleClearCart(e, itm, indx)}
                  >
                    Clear Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="checkout_right_cont_button">
            <div className="checkout_right_cont_button_btn2">
              <button
                 onClick={(e)=>handleSingleClearCart(e,itm)}
              >
                Clear Cart
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

import rec from "./Images/banner.jpg";
import "./InBetweenBanner2.css";

export default function InBetweenBanner2({ handleShoppageClick }) {
  return (
    <>
      <div className="InBetweenBanner2" style={{ backgroundImage: `url(${rec})`, maxHeight: '200px' }}>
        <div className="InBetweenBanner2_inner">
          <div className="InBetweenBanner2_label1">Gold jewellery design </div>
          <div className="InBetweenBanner2_label2">for women</div>
          <div className="InBetweenBanner2_button">
            <button onClick={e => handleShoppageClick()}>Shop Now</button>
          </div>
        </div>

      </div>
    </>
  );
}
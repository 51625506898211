import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Resetpassword.css";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";

export default function Resetpassword() {
    //   const dispatch = useDispatch();
    //   const user = useSelector(state => state.user);
      const baseurl = config.url;
      const navigate = useNavigate();
      const [disabled,setDisabled]=useState(false);
    //   const [array, setArray] = useState([]);
    const [otp, setOtp] = useState("");
      const [password, setPassword] = useState("");
      const [rePassword,setRePassword]=useState("");
    const handlePasswordBtnClick = (e) => {
        e.preventDefault();
     const email=localStorage.getItem("emailid");
            // const email="jinu@gmail.com"
     if(password==rePassword){
        setDisabled(false);
     const url = baseurl + "updatepassword";
        var data = { email: email,password:password};
        const header = {};
        axios
          .post(url, data, header)
          .then((res) => {
            // console.log("o==>",res.data);
            navigate("/Loginpage");
            alert("Successfully Reset Password");
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
        setDisabled(true);
    }
        // navigate("/Loginpage");
    };
    return (

        <div className="forgotpassword_row2">
            <div className="forgotpassword_row2_password">
                <div className="forgotpassword_row2_password_row1">
                    <h2>Reset Password</h2>
                </div>
                <div className="forgotpassword_row2_password_row2">
                    <label> Please enter new password below.</label>
                </div>
                <div className="forgotpassword_row2_password_row3">
                    <input
                        type="password"
                        placeholder=" Password"
                        value={password}
                        onChange={(e) => {setPassword(e.target.value);setDisabled(false)}}
                    />
                </div>
                <div className="forgotpassword_row2_password_row3">
                    <input
                        type="password"
                        placeholder=" Repassword"
                        value={rePassword}
                        onChange={(e) => {setRePassword(e.target.value);setDisabled(false) }}
                    />
                </div>
                {disabled?
                <div className="forgotpassword_row2_password_row5">
                    <label> Mismatch password </label>
                </div>:""
                }
                <div className="forgotpassword_row2_password_row4">
                    <button
                        onClick={(e) => handlePasswordBtnClick(e)}
                    >
                        Submit{" "}
                    </button>
                </div>
                {/* <div className="Login_lastline">
          <label> Don’t have an Account?</label>
          <label className="Login_lastline_lab2"> Create account</label>
        </div> */}
            </div>
        </div>
    )
};